
import React, { useRef, useState } from 'react';
import "./bookEventMainPage.css";
import { useDispatch } from "react-redux";
import PageLayout from "../../components/pagelayout/pagelayout";
import sidebarContentAdmin from "../../helpers/sidebarContentAdmin";
import { logOut } from "../../redux/actions/userAction";
import Select from "react-select"
import upload from '../../assets/images/upload.svg';
import { useEffect } from "react";
import { gameAllCategory, getAllObjectives, getGameDetail, getAllRegion } from "../../redux/actions/gameDetailAction";
import { getAllOrganisations } from "../../redux/actions/organisationActions";
import { useSelector } from "react-redux";
import LoadingComponent from "../../components/loader/LoadingComponent";
import { addNewGame, updateGame } from "../../redux/actions/homepageActions";
import { ToastContainer } from "react-toastify";
import { uploadFile } from "../../redux/actions/commonActions";
import remove from '../../assets/images/remove.svg';
import _isEqual from "lodash/isEqual";
import { toggleLiveGames } from '../../redux/actions/gameDetailAction';
import Header from "../../components/header/header";

import iconinstantbook from '../../assets/icons/iconinstantbook.png';
import team from '../../assets/icons/team.png';
import Footer from "../../components/footer/footer";
import Accordion from "../Accordion";
import quotes from "../../assets/icons/quotes.png";
import curiositycorner from "../../assets/icons/curiositycorner.png";
import iconTime from "../../assets/icons/icontime.png";
import icondifficulty from "../../assets/icons/icondifficulty.png";
import iconcompetition from "../../assets/icons/iconcompetition.png";
import iconparticpants from "../../assets/icons/iconparticpants.png";
import icongaurantee from "../../assets/icons/icongaurantee.png";
import iconheadcount from "../../assets/icons/iconheadcount.png";
import iconinnovative from "../../assets/icons/iconinnovative.png";
import iconaccessible from "../../assets/icons/iconaccessible.png";
import iconpricing from "../../assets/icons/iconpricing.png";
import Playbutton from "../../assets/icons/Playbutton.png";
import BingoImg from "../../assets/icons/BingoImg.png";
import Iconcoreuser from "../../assets/icons/Iconcoreuser.png";
import leftArrowImage from "../../assets/icons/leftArrowImage.png";
import rightArrowImage from "../../assets/icons/rightArrowImage.png";
import iconfilter from "../../assets/icons/iconfilter.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import planSectionBg1 from '../../assets/icons/planSectionBg1.png';
import headingIconGreen from "../../assets/icons/headingIconGreen.png";
import { BASE_URL, encryptData, S3_BASE_URL, GOOGLE_RECAPTCHA_SITE_KEY, failureAlert, IsAdmin, axiosApiInstance, videoUrl } from "../../helpers/helper";
import axios from "axios";
import { getAllOrganizationLogos } from "../../redux/actions/organizationLogoActions";
import { Link } from 'react-router-dom';
import exploreMoreIcon from "../../assets/icons/exploreMoreIcon.png";
import Tabs from "../../components/AccordionTab";
import headingIcon from "../../assets/icons/headingIcon.png";
import Spinner from "../../containers/spinwheel";
import FloatingGif from "../../containers/gif";
import ImageZoomOut from "../../containers/Discount";
import MessageModal from "../../components/modal/messagemodal";
import BookEventFilterModal from "../../components/modal/bookEventFilterModal";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import GenerateDiscount from "../../components/GenerateDiscount";
import timeIcon from "../../assets/images/time.svg";
import players from "../../assets/icons/participants.png";
import { updateFilterFormData } from "../../redux/actions/bookEventFilterActions";
import { useLocation } from 'react-router-dom';
const BookAnEventMainPage = (props) => {
    const [loaded, setLoaded] = useState(true);
    const [isCategory, setCategoryClicked] = useState(false);
    const [bookEventCategories, setBookEventCategories] = useState([]);
    let [categoryWiseGames, setGamesOnCategories] = useState([]);
    const [allGames, setallGames] = useState([]);
    const [gamesTodisplay, setgamesTodisplay] = useState([]);
    const [isAscending, setIsAscending] = useState(true);
    const [newestFirst, setNewestFirst] = useState(true);
    const [filtersCount, setFiltersSelectedCount] = useState(0);
    const [selectedCurrency, setSelectedCurrency] = useState("INR");
    const [banners, setBanners] = useState([]);
    const history = useHistory();
    const storedFormData = useSelector((state) => state.filterFormData);
    useEffect(() => {
        const games = isCategory ? categoryWiseGames : allGames;
        setgamesTodisplay(games);
    }, [allGames]);
    const getObjectKeysCountAndValues = (obj) => {
        if (obj && Object.keys(obj).length === 0) {
            return null;
        }
        const result = Object.entries(obj).reduce((acc, [key, value]) => {
            if (key === 'minCost' || key === 'maxCost') {
                if (value) {
                    if (!acc.some(item => item.key === 'costRange')) {
                        acc.push({
                            key: 'costRange',
                            value: {
                                min: obj.minCost,
                                max: obj.maxCost
                            }
                        });
                    }
                }
                return acc;
            }
            if (value !== undefined && value !== null && value !== '' && !(Array.isArray(value) && value.length === 0)) {
                acc.push({
                    key,
                    value: Array.isArray(value) ? value.length : value,
                });
            }

            return acc;
        }, []);
        return {
            count: result.length,
            keysWithValues: result
        };
    };
    useEffect(() => {
        if (storedFormData) {
            if (storedFormData.formData) {
                const result = getObjectKeysCountAndValues(storedFormData.formData);
                setFiltersSelectedCount(result.count);
            }
        }
    }, [storedFormData]);

    async function fetchAllGames() {
        setLoaded(false);
        try {
            const response = await axiosApiInstance.get(BASE_URL + "/api/bookEvent/getAllGames");
            const result = response?.data?.data;
            setLoaded(true);
            return result;
        } catch (error) {
            setLoaded(true);
            console.error('Error fetching data:', error);
            throw error;
        }
    }

    useEffect(() => {
        const callBack = async () => {
            try {
                const response = await axios.get('https://api.ipgeolocation.io/ipgeo?apiKey=e1a29c31b72d4a4a9d0ce93be30400ee')
                if (response?.data?.country_name) {
                    if (response?.data?.country_name === "India")
                        setSelectedCurrency("INR");
                    else
                        setSelectedCurrency("USD");
                }
            } catch (err) {
                console.log(err);
            }
        }
        callBack();
    }, []);


    useEffect(() => {
        const fetchGames = () => {
            fetchAllGames().then(result => {
                setallGames(result);
            });
        };

        fetchGames();
    }, []);

    useEffect(() => {
        const fetchData = async () => {
            setLoaded(false);
            try {
                const response = await axiosApiInstance.get(BASE_URL + "/api/bookEvent/getAllCategories");
                const result = response.data.data;
                setBookEventCategories(result);
                setLoaded(true);
            } catch (err) {
                setLoaded(true);
            }
        };

        fetchData();
    }, []);

    const [bookEventplatforms, setBookEventPlatforms] = useState([]);
    useEffect(() => {
        const fetchData = async () => {
            setLoaded(false);
            try {
                const response = await axiosApiInstance.get(BASE_URL + "/api/bookEvent/getAllPlatforms");
                const result = response.data.data;
                setBookEventPlatforms(result)
                setLoaded(true);
            } catch (err) {
                setLoaded(true);
            }
        };

        fetchData();
    }, []);



    useEffect(() => {
        const getAllBanners = async () => {
            try {
                setLoaded(false);
                const response = await axiosApiInstance.get(BASE_URL + "/api/bookEvent/getBookEventBanners");
                setBanners(response.data.data);
                setLoaded(true);
            } catch (error) {

            }
        };

        getAllBanners();
    }, []);

    const [focusedIdx, setFocusedIdx] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const cardsPerPage = 12;
    const tabs1 = [
        { id: 0, label: "Play" },
        { id: 1, label: "Games" },
        { id: 2, label: "Live Events" }
    ];
    const targetRef = useRef(null);
    useEffect(() => {
        if (targetRef.current) {
            const topPosition = targetRef.current.getBoundingClientRect().top + window.scrollY - 100;
            window.scrollTo({
                top: topPosition,
                behavior: 'smooth',
            });
        }
    }, [currentIndex])
    const targetRef11 = useRef(null);

    useEffect(() => {
        if (targetRef11.current) {
            const topPosition = targetRef11.current.getBoundingClientRect().top + window.scrollY - 70;
            window.scrollTo({
                top: topPosition,
                behavior: 'smooth',
            });
        }
    }, [])

    const playFaq = [
        { title: 'What is ExtraMile Play?', content: "ExtraMile Play caters to every kind of team—whether remote, offline, online, big, small, or somewhere in between, we've got the perfect adventure waiting for you!" },
        { title: 'How do I sign up for ExtraMile Play?', content: 'Signing up is as easy as a high-five! Just visit our website, choose your plan, and dive into a world where team spirit meets limitless fun.' },
        {
            title: "Can I play a game without subscribing to the platform?",
            content: "Yes, you can! Feel free to try out any game and play up to stage 1 to experience the fun and excitement firsthand before deciding to subscribe. It's the perfect way to get a feel for what ExtraMile Play has to offer!"
        },
        {
            title: " What subscription plans does ExtraMile Play offer?",
            content: "We offer flexible plans to suit every team's needs—whether you prefer monthly adventures or want to save with annual subscriptions, we've got options that fit your style of working!"
        }, {
            title: "Are there any discounts for annual subscriptions?",
            content: "Yes! Enjoy exclusive discounts when you opt for our annual subscription plans, making team-building with ExtraMile Play both rewarding and cost-effective."
        }
    ];

    const gamesFaq = [
        { title: 'What types of games are available on ExtraMile Play?', content: 'From brain-teasing puzzles to creative challenges and everything in between, ExtraMile Play offers a diverse range of games designed to spark joy and foster teamwork.' },
        { title: "Can we customise the games to fit our company's theme?", content: "Absolutely! Personalise your team's experience by customising games to reflect your company's culture, values, and goals—making each activity uniquely yours. The options are available here. " },
        { title: "How many players can participate in a game?", content: "Whether your team is small and intimate or large and dynamic, our games are crafted to accommodate all group sizes, ensuring everyone can join in on the fun! Know more about it here. " },
        { title: "Are the games suitable for remote teams?", content: "Yes! ExtraMile Play is perfect for remote teams, offering engaging activities that bridge distances and foster collaboration, no matter where your team members are located." },
    ];
    const liveEventsFaq = [
        { title: "Can I use the games to do LIVE events on Zoom, MS Teams etc?", content: "A 100% yes! Playing ExtraMile Play's games for LIVE events on Zoom, MS Teams, or any virtual platform is extremely easy. We believe in creating engaging experiences that bring teams together no matter where they are, ensuring every event is a hit!" },
        { title: "Are there facilitators available to help run the LIVE events?   ", content: "Yes! Our expert facilitators ensure your LIVE events run smoothly, guiding your team through engaging activities that enhance teamwork. When confirming the event, we share profiles of our experts for you to choose from, providing the perfect fit to help run your event." },
    ];
    let accordionItems;
    switch (focusedIdx) {
        case 1:
            accordionItems = gamesFaq;
            break;
        case 2:
            accordionItems = liveEventsFaq;
            break;
        default:
            accordionItems = playFaq;
    }

    const [clickedButton, setClickedButton] = useState('');

    const handleClick = async (buttonName) => {
        setCurrentIndex(0);
        const result = await fetchAllGames();

        setallGames(result);
        setCategoryClicked(false);
        setClickedButton(buttonName);
        if (buttonName == 'priceLowHigh') {
            const sortedEvents = [...result].sort((a, b) => {
                const costA = parseFloat(a.costOfEvent[selectedCurrency]);
                const costB = parseFloat(b.costOfEvent[selectedCurrency]);
                return isAscending ? costA - costB : costB - costA;
            });
            setgamesTodisplay(sortedEvents);
            setIsAscending(!isAscending);
        }
        if (buttonName == 'newest') {
            const sortedEvents = [...result].sort((a, b) => {
                return newestFirst
                    ? new Date(b.createdAt) - new Date(a.createdAt)
                    : new Date(a.createdAt) - new Date(b.createdAt);
            });
            setgamesTodisplay(sortedEvents);
            setNewestFirst(!newestFirst);
        }
    };

    const { orgLogos } = useSelector(state => state.organizationLogos);
    useEffect(() => {
        dispatch(getAllOrganizationLogos(""));
    }, []);
    const [allOrgLogos, setLogos] = useState([]);
    const responsive4 = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
            slidesToSlide: 1,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            slidesToSlide: 1,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1,
        },
    };
    const responsive23 = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 1436 },
            items: 4
        },
        desktop: {
            breakpoint: { max: 1435, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2
        }
    };
    const isAdmin = IsAdmin();
    const dispatch = useDispatch();
    const signOut = async () => {
        await dispatch(logOut());
        if (isAdmin) props.history.push("/admin");
        else props.history.push("/");
    };
    useEffect(() => {
        if (orgLogos) {
            setLogos(orgLogos)
        }
    }, [orgLogos]);

    const [planformData, setPlanFormData] = useState({
        companyName: '',
        email: ''
    });
    const [planformerrors, setPlanFormErrors] = useState({
        companyName: '',
        email: ''
    });
    const [openEmailExistModal, setopenEmailExistModal] = useState(false);
    const [showSpinner, setShowSpinner] = useState(false);
    const [isSpinning, setIsSpinning] = useState(false);
    const [showGif, setGif] = useState(false);
    const validatePlanForm = () => {
        const planformerrors = {};
        if (!planformData.companyName) {
            planformerrors.companyName = 'Please fill out this field';
        }
        if (!planformData.email) {
            planformerrors.email = 'Please fill out this field';
        } else if (!/\S+@\S+\.\S+/.test(planformData.email)) {
            planformerrors.email = 'Enter valid email address';
        }
        setPlanFormErrors(planformerrors);
        return Object.keys(planformerrors).length === 0;
    };
    const handlePlanFormSubmit = async (e) => {
        e.preventDefault();
        if (validatePlanForm()) {
            setLoaded(false)
            const checkEmail = await axios.post(BASE_URL + "/api/user/check/new-user-email", { email: planformData.email });
            if (checkEmail && checkEmail.data.message === "email is already taken") {
                setopenEmailExistModal(true);
                setLoaded(true)
            }
            else {
                sessionStorage.setItem('companyInfo', JSON.stringify(planformData));
                setShowSpinner(true);
                setIsSpinning(true);
                setTimeout(() => {
                    setIsSpinning(false);
                    setTimeout(() => {
                        setShowSpinner(false);
                    }, 2000);

                    setGif(true);
                }, 5000);

                setLoaded(true)
            }
        }
    };
    const handlePlanFormChange = (e) => {
        const { name, value } = e.target;
        setPlanFormData({
            ...planformData,
            [name]: value
        });

        if (name == 'email') {
            setFormValues(prevValues => ({
                ...prevValues,
                email: value,
            }));
        }
        if (name == 'companyName') {
            setFormValues(prevValues => ({
                ...prevValues,
                companyname: value
            }));
        }

    };
    const [formValues, setFormValues] = useState({
        firstname: "",
        lastname: "",
        email: "",
        companyname: "",
        comments: ""
    });
    const [isDiscountSet, setIsDiscountSet] = useState(false);
    const [isSet, Isset] = useState(true);
    const [discountValue, setDiscountValue] = useState(null);
    const [isGif, setShowGif] = useState(false);
    const [showImage, setShowImage] = useState(false);
    const [openMessageModal, setopenMessageModal] = useState(false);
    const [openFilterModal, setOpenBookEventFilterModal] = useState(false);
    const [successImage, setSuccessImage] = useState(false);
    const handleValueChange2 = (value) => {
        setDiscountValue(value);
        sessionStorage.setItem('discount', value);
    };
    const hideImage = () => {
        setShowImage(false);
        setopenMessageModal(true);
        setGif(false);
        setShowGif(false);

    };
    const openFilter = () => {
        setOpenBookEventFilterModal(true);
    };

    const filterGamesOnCategoryId = (categoryId) => {
        setCategoryClicked(true);
        setSelectedCategoryId(categoryId);
        const filtered = allGames.filter(game =>
            game.categories.some(category => category.id === categoryId)
        );
        setgamesTodisplay(filtered);
    };

    useEffect(() => {
        if (showGif) {
            setShowGif(true);
            const timer = setTimeout(() => {
                setShowGif(false);
                setSuccessImage(true);
                Isset(false);
            }, 4000);
            return () => clearTimeout(timer);
        }
    }, [showGif]);
    useEffect(() => {
        if (successImage) {
            setShowImage(true)
        }
    }, [successImage]);

    const handleCardDetail = (e, gameId) => {
        e.preventDefault();
        history.push("/event-detail/" + gameId)
    }

    const [totalScore, setTotalScore] = useState(0);

    const handleScore = (score) => {
        sessionStorage.setItem('discount', score);
        setTotalScore(prevScore => prevScore + score);
        setShowImage(true);
    };
    const [expandedCards, setExpandedCards] = useState({});

    const toggleReadMore = (cardId) => {
        setExpandedCards(prevState => ({
            ...prevState,
            [cardId]: !prevState[cardId],
        }));
    };

    const handleCategoryChange = (categories) => {
        setCategoryClicked(false);
        setallGames(categories);
    };

    const [isMobile, setIsMobile] = useState(false);

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 464);
    };

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);
    const mainBanner = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    };
    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1500 },
            items: 6,
            partialVisibilityGutter: 40,
        },
        tablet: {
            breakpoint: { max: 1500, min: 1024 },
            items: 4,
            partialVisibilityGutter: 30,
        },

        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            partialVisibilityGutter: 30,
        },
        medium: {
            breakpoint: { max: 1024, min: 768 },
            items: 3,
            partialVisibilityGutter: 30,
        }
    };
    const handleClearFilters = async (clear) => {
        if (clear) {
            setFiltersSelectedCount(0);
            const result = await fetchAllGames();
            dispatch(updateFilterFormData(null));
            setallGames(result);
        }
    };
    const [selectedCategoryId, setSelectedCategoryId] = useState(null);



    return (
        <>
            {
                openFilterModal &&
                <BookEventFilterModal modalid={"filter-modal"} toggle={openFilterModal} setOpenBookEventFilterModal={setOpenBookEventFilterModal} bookEventCategories={bookEventCategories} bookEventplatforms={bookEventplatforms} onCategoriesChange={handleCategoryChange} onClearFilters={handleClearFilters} />
            }
            {
                openMessageModal &&
                <MessageModal modalid={"message-modal"} toggle={openMessageModal} setopenMessageModal={setopenMessageModal} />
            }
            <ToastContainer position='bottom-center' />
            <LoadingComponent loaded={loaded} />
            <Header profile {...props} signOut={signOut} {...props} isAdmin={isAdmin} />


            <div className="section section1 " id="windows-section" ref={targetRef11}>
                <div className="image-container ">
                    <Carousel responsive={mainBanner}>
                        {banners.map((logo, index) => (
                            <div className="carousel-games-item flex justify-center items-center m-0" key={index}>
                                <img
                                    src={`${S3_BASE_URL}${logo.bannerPath}`}
                                    className="w-full h-full object-cover desktopView"
                                    alt={`Banner ${index}`}
                                />
                                <img
                                    src={`${S3_BASE_URL}${logo.mobileBannerPath}`}
                                    alt={`Logo ${index} Mobile`}
                                    className="w-full h-full object-contain mobileView"
                                />
                            </div>
                        ))}
                    </Carousel>


                </div>


            </div>

            <section className="section py-12 mx-auto logoSectionBookEvent">

                <div className="relative flex justify-center md:justify-start mt-0 sm:mt-0 md:mt-6 ml-0 lg:mt-6  lg:ml-12">
                    <h2 className="text-center relative inline-block mb-3 text-[22px] md:text-[40px] font-bold lg:ml-12">
                        Teams Who  <span className="textRed">Loves Us</span>
                        <img
                            src={headingIcon}
                            alt="Decoration"
                            className="absolute top-[-10px] right-[-30px] w-[25px] h-[23px] object-contain"
                        />
                    </h2>
                </div>
                <Carousel
                    autoPlay
                    additionalTransfrom={0}
                    arrows={false}
                    autoPlaySpeed={3000}
                    centerMode={false}
                    className=""
                    containerClass="container-with-dots"
                    dotListClass=""
                    draggable
                    focusOnSelect={false}
                    infinite
                    itemClass=""
                    keyBoardControl
                    minimumTouchDrag={80}
                    pauseOnHover
                    renderArrowsWhenDisabled={false}
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    responsive={{
                        desktop: {
                            breakpoint: {
                                max: 3000,
                                min: 1070
                            },
                            items: 6,
                            partialVisibilityGutter: 40
                        },
                        mobile: {
                            breakpoint: {
                                max: 464,
                                min: 0
                            },
                            items: 2,
                            partialVisibilityGutter: 30
                        },
                        tablet: {
                            breakpoint: {
                                max: 1024,
                                min: 464
                            },
                            items: 2,
                            partialVisibilityGutter: 30
                        }
                    }}
                    rewind={false}
                    rewindWithAnimation={false}
                    rtl={false}
                    shouldResetAutoplay
                    showDots={false}
                    sliderClass=""
                    slidesToSlide={1}
                    swipeable
                >
                    {allOrgLogos.map((logo, index) => (
                        <div key={index} className=" flex justify-center">
                            <img
                                src={`${S3_BASE_URL}${logo.path}`}
                                alt={`Logo ${index}`}
                                className="w-[150px] h-auto  object-contain lg:w-[150px] lg:h-auto"
                            />
                        </div>
                    ))}
                </Carousel>
            </section>
            <div className="gradient_1">
                <section className="section py-12 mx-auto max-w-[100%]">
                    {!isMobile && (
                        <Carousel
                            autoPlay={false}
                            additionalTransfrom={0}
                            arrows={true}
                            autoPlaySpeed={3000}
                            centerMode={false}
                            className="relative"
                            containerClass="container-with-dots"
                            draggable
                            infinite
                            itemClass="carousel-item"
                            keyBoardControl
                            minimumTouchDrag={80}
                            pauseOnHover
                            responsive={{
                                desktop: {
                                    breakpoint: { max: 3000, min: 1400 },
                                    items: 8,
                                    partialVisibilityGutter: 40,
                                },
                                medium: {
                                    breakpoint: { max: 1400, min: 464 },
                                    items: 6,
                                    partialVisibilityGutter: 30,
                                },
                            }}
                            slidesToSlide={1}
                            swipeable
                        >
                            {bookEventCategories.map((logo, index) => (
                                <div key={index} className="cursor-pointer flex flex-col justify-center items-center" onClick={() => filterGamesOnCategoryId(logo.id)}>
                                    <img
                                        src={`${S3_BASE_URL}${logo.categoryImage}`}
                                        alt={`Logo ${index}`}
                                        className="md:w-[150px] md:h-[150px] w-[100px] h-[100px] rounded-full object-cover"
                                    />
                                    <h5
                                        className={`mt-3 text-lg font-bold capitalize ${selectedCategoryId === logo.id && isCategory ? 'underline underline-offset-2' : ''
                                            }`}
                                    >{logo.title}</h5>

                                </div>
                            ))}
                        </Carousel>
                    )}

                    {isMobile && (
                        <Carousel
                            autoPlay={false}
                            additionalTransfrom={0}
                            arrows={false}
                            autoPlaySpeed={3000}
                            centerMode={false}
                            className="relative"
                            containerClass="container-with-dots"
                            draggable
                            infinite
                            itemClass="carousel-item"
                            keyBoardControl
                            minimumTouchDrag={80}
                            pauseOnHover
                            responsive={{
                                mobile: {
                                    breakpoint: { max: 464, min: 0 },
                                    items: 2,
                                    partialVisibilityGutter: 30,
                                },
                            }}
                            slidesToSlide={1}
                            swipeable
                        >
                            {bookEventCategories.map((logo, index) => (
                                <div key={index} className="flex flex-col justify-center items-center" onClick={() => filterGamesOnCategoryId(logo.id)}>
                                    <img
                                        src={`${S3_BASE_URL}${logo.categoryImage}`}
                                        alt={`Logo ${index}`}
                                        className="md:w-[150px] md:h-[150px] w-[130px] h-[130px] rounded-full object-cover"
                                    />
                                    <h5
                                        className={`mt-3 text-lg font-bold capitalize ${selectedCategoryId === logo.id && isCategory ? 'underline underline-offset-2' : ''
                                            }`}
                                    >{logo.title}</h5>

                                </div>
                            ))}
                        </Carousel>
                    )}

                </section>
            </div>

            <div className="gradient_2">
                <section className="pt-12 pb-6 mx-auto max-w-[90%]">
                    <div className="flex flex-col lg:flex-row justify-between items-center border-gray-300 pb-4">

                        <div className="mb-5 lg:mb-0 lg:mr-4 lg:ml-1">
                            <button onClick={() => openFilter()} className="filter-button-with-badge">
                                <img src={iconfilter} alt="Icon" className="mr-2 w-4 h-4" />
                                <span>Filters</span>
                                {filtersCount > 0 && <span className="badge">{filtersCount}</span>}
                            </button>
                        </div>
                        <div class="inline-flex">
                            <a

                                style={{ borderColor: '#ddd', borderStyle: 'solid', cursor: 'pointer' }}
                                className={`px-4  text-lg font-medium text-gray-900 border-r-2 ${clickedButton === 'newest' ? 'font-bold ' : ''}`}
                                onClick={() => handleClick('newest')}
                            >
                                <span className={`${clickedButton === 'newest' ? 'font-bold underline' : ''}`}>Newest</span>
                            </a>
                            <a

                                style={{ borderColor: '#ddd', borderStyle: 'solid', cursor: 'pointer' }}
                                className={`px-4 text-lg font-medium text-gray-900 ${clickedButton === 'priceLowHigh' ? 'font-bold ' : ''}`}
                                onClick={() => handleClick('priceLowHigh')}
                            >
                                <span className={`${clickedButton === 'priceLowHigh' ? 'font-bold underline' : ''}`}>{isAscending ? 'Price: High / Low' : 'Price: Low / High'}</span>
                            </a>

                        </div>
                    </div>
                </section>



                <section className="section p-4 lg:pb-12 mx-auto sm:max-w-[100%] lg:max-w-[92%] sm:p-0" ref={targetRef}>
                    {gamesTodisplay && gamesTodisplay.length > 0 && (<div className="relative mb-8 flex justify-center md:justify-start">


                        <h2 className="text-center relative inline-block mb-2
                                   text-[22px] md:text-[40px] font-bold">
                            Explore <span className="textDark">Events</span>
                            <img
                                src={headingIcon}
                                alt="Decoration"
                                className="absolute top-[-3px] right-[-25px] w-[24px] h-[23px] object-contain"
                            />
                        </h2>


                    </div>
                    )}

                    {gamesTodisplay && gamesTodisplay.length == 0 && (<div className="w-full h-52 flex flex-col items-center justify-center  border border-gray-800 rounded-lg p-4"> {/* Added border and padding */}
                        <h1 className="text-xl font-bold">No Events Found</h1>
                        <p className="mt-2 text-center">Try changing or reseting your filters</p>
                        <button onClick={() => handleClearFilters(true)} class="mt-4  text-gray-900 hover:text-white border border-gray-800 hover:bg-gray-900 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2 ">Reset Filters</button>

                    </div>)}
                    <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">

                        {gamesTodisplay && gamesTodisplay.length > 0 && gamesTodisplay?.map((game, index) => {
                            const cards = {
                                id: game.id,
                                gameBanner: game.gameBanner,
                                title: game.title,
                                content: game.description,
                                participants: game.maxPlayers,
                                time: game.duration,
                                cost: game.costOfEvent[selectedCurrency],
                                typeOfEvent: game.typeOfEvent
                            };


                            if (
                                index >= currentIndex &&
                                index < currentIndex + cardsPerPage
                            ) {
                                return (
                                    <div
                                        key={cards.id}
                                        className="relative bg-white shadowBookEventCard rounded-lg flex flex-col items-center w-full lg:w-90 bookEventCard overflow-hidden"
                                        onClick={(e) => handleCardDetail(e, cards.id)} // Handle card click
                                    >
                                        <img
                                            src={S3_BASE_URL + cards?.gameBanner}
                                            alt={`Card ${cards.id} Image`}
                                            className="w-full h-32 object-cover rounded-md mb-0 bookEventCardImg"
                                        />
                                        <div className="p-5 text-left w-full flex flex-col" style={{ minHeight: '200px', flexGrow: 1 }}>
                                            <h5 className="mb-4 text-xl font-medium text-gray-900 font-bold capitalize titleHover">{cards.title}</h5>

                                            <div className="flex justify-start w-full mb-3">
                                                <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 640 512" class="mr-1 w-[22px] h-[20px] text-black" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M192 256c61.9 0 112-50.1 112-112S253.9 32 192 32 80 82.1 80 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C51.6 288 0 339.6 0 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zM480 256c53 0 96-43 96-96s-43-96-96-96-96 43-96 96 43 96 96 96zm48 32h-3.8c-13.9 4.8-28.6 8-44.2 8s-30.3-3.2-44.2-8H432c-20.4 0-39.2 5.9-55.7 15.4 24.4 26.3 39.7 61.2 39.7 99.8v38.4c0 2.2-.5 4.3-.6 6.4H592c26.5 0 48-21.5 48-48 0-61.9-50.1-112-112-112z"></path></svg>
                                                <span className="text-md font-medium text-gray-900 font-bold ml-0">Upto {cards.participants} Participants</span>

                                                {/* <span className="text-md font-medium text-gray-900 font-bold">Upto {cards.participants} Participants</span> */}
                                                <img
                                                    src={timeIcon}
                                                    alt="Time icon"
                                                    className="ml-3 h-6 w-6 object-contain"
                                                />
                                                <span className="text-md font-medium text-gray-900 font-bold ml-1">{cards.time} mins</span>
                                            </div>

                                            <p className="mb-3 text-md font-normal  flex-grow descriptionColor">
                                                {expandedCards[cards.id] ? cards.content : `${cards.content.slice(0, 150)}... `}
                                                {cards.content.length > 150 && (
                                                    <span
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            toggleReadMore(cards.id);
                                                        }}
                                                        className="text-blue-500 cursor-pointer"
                                                    >
                                                        {expandedCards[cards.id] ? 'Read Less' : 'Read More'}
                                                    </span>
                                                )}
                                            </p>

                                            <div className="flex justify-between items-center w-full mb-1">
                                                <span className="text-md font-medium text-gray-900 font-bold">
                                                    ₹ {cards.cost} {cards.typeOfEvent === 'offline' ? "/ Per Person" : ""}
                                                </span>
                                                <button
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleCardDetail(e, cards.id);
                                                    }}
                                                    className="btn btn-primary btn-sm viewDetailsBtn"
                                                >
                                                    View Details
                                                </button>
                                            </div>
                                        </div>


                                    </div>
                                );
                            }
                            return null;
                        })}
                    </div>

                    {gamesTodisplay && gamesTodisplay.length > 12 && (
                        <div className="flex justify-center mt-12 space-x-4">
                            <button
                                onClick={() => {
                                    if (currentIndex - cardsPerPage >= 0) {
                                        setCurrentIndex(currentIndex - cardsPerPage);
                                    }
                                }}
                                disabled={currentIndex === 0}
                                className="bg-blue-500 text-white px-4 py-2 rounded prevbtn transition duration-300 ease-in-out hover:bg-gray-300 hover:opacity-75" // Change on hover to light grey and reduce opacity
                            >
                                <div className="flex items-center">
                                    <img src={leftArrowImage} alt="Left Arrow" className="ml-0 font-21" />
                                    <span className="ml-2">Previous</span>
                                </div>
                            </button>
                            <button
                                onClick={() => {
                                    if (currentIndex + cardsPerPage < gamesTodisplay?.length) {
                                        setCurrentIndex(currentIndex + cardsPerPage);
                                    }
                                }}
                                disabled={currentIndex + cardsPerPage >= gamesTodisplay?.length}
                                className="bg-blue-500 text-white px-4 py-2 rounded nextbtn transition duration-300 ease-in-out hover:bg-gray-300 hover:opacity-75" // Change on hover to light grey and reduce opacity
                            >
                                <div className="flex items-center">
                                    <span className="mr-2">Next</span>
                                    <img src={rightArrowImage} alt="Right Arrow" className="ml-1" />
                                </div>
                            </button>
                        </div>
                    )}

                </section>
            </div>
            <GenerateDiscount onScore={handleScore}
            />
            {showImage && (
                <div>
                    <ImageZoomOut showImage={showImage} discountValue={totalScore} onClose={hideImage} />
                </div>
            )}

            <section className="section10" >
                <div className="games-accordion-bookevent">
                    <div className="title-wrapper">
                        <div className="section-wrapper">
                            <div className="title-wrapper1">
                                <h2>
                                    Frequently Asked<span className="text-highlight"> Questions </span>
                                </h2>
                                <p className="description">
                                    Got questions? Check out our FAQs for all the info you may need or write to us if
                                    we don’t answer them all.
                                </p>
                            </div>
                            <div className="explore-more">
                                <Link to={`/faq`} className="btn black">
                                    <span style={{ marginRight: '10px' }}>  Got Questions</span>
                                    <img src={exploreMoreIcon} alt="Icon" class="btn-icon" />
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="image-wrapper">
                        <div className="button-group">
                            <div className="buttongrp" style={{ marginBottom: '1rem', width: '100%' }}>
                                <Tabs value={focusedIdx} onChange={setFocusedIdx} tabs={tabs1} />
                            </div>
                            <div className="accordion"><Accordion items={accordionItems} page="bookEvent" /></div>
                        </div>
                    </div>
                </div>
            </section>
            <div className="relative z-10 ">
                <Footer {...props} isAdmin={isAdmin} /> </div>

        </>
    )
}

export default BookAnEventMainPage