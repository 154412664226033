import React, { useEffect, useState, useRef } from "react";
import "./nonLoggedIn.css";
import headingIcon from "../../assets/icons/headingIcon.png";
import headingIconGreen from "../../assets/icons/headingIconGreen.png";
import backgroundGradient from "../../assets/icons/bglandingpage.png";
import flexible from "../../assets/icons/Flexible.png";
import customised from "../../assets/icons/Customized-Experience.png";
import support from "../../assets/icons/headingIconGreen.png";
import seamless from "../../assets/icons/Seamless-Integration.png";
import impact from "../../assets/icons/Measurable-Impact.png";
import InnovativeGames from "../../assets/icons/Innovative-Games.png";
import liveengagement from "../../assets/icons/liveengagement.png";
import explorecreativity from "../../assets/icons/explorecreativity.png";
import customisegames from "../../assets/icons/customisegames.png";
import rocketRedquestDemo from "../../assets/icons/rocketRedquestDemo.png";
import exploreMoreIcon from "../../assets/icons/exploreMoreIcon.png";
import rocket from "../../assets/icons/rocket.png";
import Statistics from '../../containers/Statistics';
import Accordion from "../../containers/Accordion";
import LogoCarousel from "../../containers/logoCarousel";
import TestimonialCarousel from "../../containers/TestimonialCarousel";
import GameSection from "../../containers/gamesection";
import "reactjs-popup/dist/index.css";
import 'react-multi-carousel/lib/styles.css';
import { BASE_URL, encryptData, S3_BASE_URL, GOOGLE_RECAPTCHA_SITE_KEY, failureAlert, axiosApiInstance, videoUrl } from "../../helpers/helper";
import "reactjs-popup/dist/index.css";
import axios from "axios";
import "../../containers/dashboard/dashboard.css";
import _debounce from "lodash/debounce"
import Pollimg from '../../assets/images/poll.png'
import bg from '../../assets/icons/dummy.jpg'
import desktopImg from '../../assets/icons/BG.jpg'
import mobileImg from '../../assets/icons/Bgmob.png'
import img1 from '../../assets/icons/image1.png'
import img2 from '../../assets/icons/image2.png'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { useDispatch, useSelector } from "react-redux";
import {
  getGamesByCategory,
  getAllGames,
  getBannerGames,
  homeSearchV3,
  homeSearch,
  getContactUs,
  getCurrentlyPlayingGames,
  getPreviouslyPlayedGames,
  getAllSlogans,
  backToDefaultBanners,
  addBannerGameBulk,
  updateBannerGameBulk,
  updateBannersDetail,
  updateSloganBulk,
  addBannerGame,
  updateSlogan
} from "../../redux/actions/homepageActions";
import { gameAllCategory, getAllObjectives } from "../../redux/actions/gameDetailAction";
import { getAllOrganizationLogos } from "../../redux/actions/organizationLogoActions";
import { getAllUserTestimonials } from "../../redux/actions/userTestimonialActions";
import ReCAPTCHA from "react-google-recaptcha";
import { ToastContainer, toast } from "react-toastify";
import { Link } from 'react-router-dom';
import LoadingComponent from "../../components/loader/LoadingComponent";
import PhoneInput from "react-phone-input-2";
import DemoRequestModal from "../../components/modal/demorequestmodal";
import MessageModal from "../../components/modal/messagemodal";
import EmailExistModal from "../../components/modal/emailExistModal";
import Tabs from "../../components/AccordionTab";
import DynamicButtonsTabs from "../../components/dynamicButtonTab";
import { getAllFeatureBanners } from "../../redux/actions/featureBannerActions";
import { useMediaQuery } from 'react-responsive';
import Spinner from "../../containers/spinwheel";
import ImageZoomOut from "../../containers/Discount";
import FloatingGif from "../../containers/gif";
import rocketgif from "../../assets/icons/rocket.gif";
import bgPink from '../../assets/icons/bgPink.png'
import ReactPlayer from "react-player";
import { border, margin } from "synonyms/dictionary";
import White_Logo from "../../assets/icons/White_Logo.gif";
import GenerateDiscount from "../../components/GenerateDiscount";
const statisticsData = [
  { target: 180, text: "Experience" },
  { target: 150, text: "Organizations" },
  { target: 15, text: "Countries" },
  { target: 300000, text: "Users" }
];
const NonLoggedIn = ({ onStatusChange, onButtonClick, onBtnClick }) => {

  useEffect(() => {
    const headerElement = document.querySelector('.header');
    const headerImage = document.querySelector('.header-left a img');
    const loginButton = document.querySelector('.header-right a button');
    const dropdown = document.querySelector('.transparentStyle');
    const dropdown1 = document.querySelector('.transparentStyle1');
    const dropdown2 = document.querySelector('.transparentStyle2');
    headerImage.src = White_Logo;
    headerElement.style.backgroundColor = 'transparent';
    headerElement.style.boxShadow = 'none';
    loginButton.style.backgroundColor = 'rgba(255, 255, 255, 0.2)';
    loginButton.style.border = '1px solid #ffffff';
    dropdown.style.backgroundColor = ' rgba(0, 0, 0, 1)';
    dropdown1.style.backgroundColor = ' rgba(0, 0, 0, 1)';
    dropdown2.style.backgroundColor = ' rgba(0, 0, 0, 1)';

    const handleScroll = () => {
      if (loginButton) {

        if (window.scrollY > 50) {
          loginButton.style.backgroundColor = '';
          dropdown.style.backgroundColor = '#ffffff';
          dropdown1.style.backgroundColor = '#ffffff';
          dropdown2.style.backgroundColor = '#ffffff';
        } else {
          loginButton.style.backgroundColor = 'rgba(255, 255, 255, 0.2)';
          dropdown.style.backgroundColor = ' rgba(0, 0, 0, 1)';
          dropdown1.style.backgroundColor = ' rgba(0, 0, 0, 1)';
          dropdown2.style.backgroundColor = ' rgba(0, 0, 0, 1)';
        }
      }
      if (headerImage) {
        if (window.scrollY > 50) {
          headerImage.src = S3_BASE_URL + "commons/logo_extramile.gif";
        } else {
          headerImage.src = White_Logo;
        }
      }
      if (headerElement) {
        if (window.scrollY > 50) {
          headerElement.style.backgroundColor = 'white';
          headerElement.style.color = 'black';
          headerElement.style.boxShadow = '0 4px 6px rgba(0, 0, 0, 0.1)';
        } else {
          headerElement.style.backgroundColor = 'transparent';
          headerElement.style.color = 'white';
          headerElement.style.boxShadow = 'none';
        }
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      if (headerElement) {
        headerElement.style.backgroundColor = '';
        headerElement.style.color = '';
        headerElement.style.padding = '';
        headerElement.style.boxShadow = '';
        loginButton.style.backgroundColor = '';
        dropdown.style.backgroundColor = '#ffffff';
        dropdown1.style.backgroundColor = '#ffffff';
        dropdown2.style.backgroundColor = '#ffffff';
      }
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const [isAnimating, setIsAnimating] = useState(false);
  const [isRocketGone, setIsRocketGone] = useState(false);

  const handleButtonClick6 = () => {
    setIsAnimating(true);
    setTimeout(() => {
      scrollToSection9();
      setIsAnimating(false);
    }, 1500);

  };
  const [play, setPlay] = useState(false);
  const [disablePause, setDisablePause] = useState(true);
  const sectionOneRef = useRef(null);
  const sectionTwoRef = useRef(null);
  const containerRef = useRef(null);
  const [showImage, setShowImage] = useState(false);
  const handleHideImage = () => {
    setShowImage(false);
    setopenMessageModal(true);
  };
  const [isHovered, setIsHovered] = useState(false);
  const [shouldAnimate, setShouldAnimate] = useState(false);

  useEffect(() => {
    let timer;
    if (isHovered) {
      timer = setTimeout(() => {
        setShouldAnimate(true);
      }, 2000);
    } else {
      setShouldAnimate(false);
      clearTimeout(timer);
    }
    return () => clearTimeout(timer);
  }, [isHovered]);

  const scrollToSection = (sectionRef) => {
    sectionRef.current.scrollIntoView({ behavior: 'smooth' });
  };

  const [categorylist, setCategoriesAndObjectives] = useState([]);
  const [isLoadingCategoryGames, setloading] = useState(true);
  const [carouselKey, setCarouselKey] = useState(0);
  const { gameCategory } = useSelector((state) => state.gameAllCategory);
  const { gameObjectives } = useSelector(state => state.getAllObjectives);
  const [categoryWiseGames, setGamesForActiveCategory] = useState([]);
  const [defaultActiveButton, setDefaultButtonID] = useState();
  const [activeButton, setActiveButton] = useState(gameCategory?.data[0].id);
  const GetSlogans = useSelector(state => state.getSlogans);
  const { allSlogans } = GetSlogans;
  const BannerGamesState = useSelector((state) => state.bannerGames);
  const gamesBasedOnCategory = useSelector((state) => state.gamesByCategory);
  const { orgLogos } = useSelector(state => state.organizationLogos);
  const { bannerGames } = BannerGamesState;
  const dispatch = useDispatch();
  const [allOrgLogos, setLogos] = useState([]);

  const responsivelogos = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  const mainBanner = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1500 },
      items: 6,
      partialVisibilityGutter: 40,
    },
    tablet: {
      breakpoint: { max: 1500, min: 1024 },
      items: 4,
      partialVisibilityGutter: 30,
    },

    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      partialVisibilityGutter: 30,
    },
    medium: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
      partialVisibilityGutter: 30,
    }
  };

  const responsive23 = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 6
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2
    }
  };

  const [filteredSlogans, setFilteredSlogans] = useState([]);
  const [banners, setBanners] = useState([]);
  const { userTestimonial } = useSelector(state => state.userTestimonial);
  const [planformData, setPlanFormData] = useState({
    companyName: '',
    email: ''
  });
  const [planformerrors, setPlanFormErrors] = useState({
    companyName: '',
    email: ''
  });

  useEffect(() => {
    // Assuming you have a way to access session data (e.g., localStorage, sessionStorage, or a context)
    const companyInfo = JSON.parse(sessionStorage.getItem('companyInfo')) || {};

    if (companyInfo && companyInfo.email && companyInfo.companyName) {
      setPlanFormData({
        companyName: companyInfo.companyName,
        email: companyInfo.email
      });
      setFormValues(prevValues => ({
        ...prevValues,
        email: companyInfo.email,
        companyname: companyInfo.companyName
      }));
    }
  }, []);
  const validatePlanForm = () => {
    const planformerrors = {};
    if (!planformData.companyName) {
      planformerrors.companyName = 'Please fill out this field';
    }
    if (!planformData.email) {
      planformerrors.email = 'Please fill out this field';
    } else if (!/\S+@\S+\.\S+/.test(planformData.email)) {
      planformerrors.email = 'Enter valid email address';
    }
    setPlanFormErrors(planformerrors);
    return Object.keys(planformerrors).length === 0;
  };
  const [isGif, setShowGif] = useState(false);
  const [showGif, setGif] = useState(false);
  const [successImage, setSuccessImage] = useState(false);
  const [isSet, Isset] = useState(true);

  useEffect(() => {
    if (showGif) {
      setShowGif(true);
      const timer = setTimeout(() => {
        setShowGif(false);
        setSuccessImage(true);
        Isset(false);
      }, 4000);
      return () => clearTimeout(timer);
    }
  }, [showGif]);

  useEffect(() => {
    if (successImage) {
      setShowImage(true)
    }
  }, [successImage]);

  const handlePlanFormChange = (e) => {
    const { name, value } = e.target;
    setPlanFormData({
      ...planformData,
      [name]: value
    });

    if (name == 'email') {
      setFormValues(prevValues => ({
        ...prevValues,
        email: value,  // Set the new email
      }));
    }
    if (name == 'companyName') {
      setFormValues(prevValues => ({
        ...prevValues,
        companyname: value
      }));
    }

  };
  const handlePlanFormSubmit = async (e) => {
    e.preventDefault();
    if (validatePlanForm()) {
      setLoaded(false)
      const checkEmail = await axios.post(BASE_URL + "/api/user/check/new-user-email", { email: planformData.email });
      if (checkEmail && checkEmail.data.message === "email is already taken") {
        setopenEmailExistModal(true);
        setLoaded(true)
      }
      else {
        sessionStorage.setItem('companyInfo', JSON.stringify(planformData));
        setShowSpinner(true);
        setIsSpinning(true);
        setTimeout(() => {
          setIsSpinning(false);
          setTimeout(() => {
            setShowSpinner(false);
          }, 2000);

          setGif(true);
        }, 5000);
        // setPlanFormData({
        //   companyName: '',
        //   email: ''
        // });
        setLoaded(true)
      }
    }
  };


  useEffect(() => {
    dispatch(gameAllCategory());
    dispatch(getAllObjectives());
    dispatch(getBannerGames(false, "NONLOGIN", "NONSUBSCRIBED", '', false));
    dispatch(getAllSlogans(false, "NONLOGIN", "NONSUBSCRIBED", '', false));

  }, []);

  useEffect(() => {
    dispatch(getAllOrganizationLogos(""));
    dispatch(getAllFeatureBanners(""));
  }, []);

  useEffect(() => {
    dispatch(getAllUserTestimonials(""));
  }, []);

  useEffect(() => {
    if (categorylist) {
      setFocusedIdxDynamicButton(categorylist[0]?.id);
    }

  }, [categorylist]);

  useEffect(() => {
    if (orgLogos) {
      setLogos(orgLogos)
    }
  }, [orgLogos]);

  useEffect(() => {
    if ((gamesBasedOnCategory && gamesBasedOnCategory.loading) || (gameCategory && gameCategory.loading) || (GetSlogans && GetSlogans.loading) || (BannerGamesState && BannerGamesState.loading) || !isLoadingCategoryGames) {
      onStatusChange(false);
    }
    else {
      onStatusChange(true);
    }

  }, [onStatusChange, gameCategory, BannerGamesState, GetSlogans, gamesBasedOnCategory, isLoadingCategoryGames]);

  useEffect(() => {
    const namesArray = [
      "Diversity & Inclusion",
      "Meeting Energisers",
      "Team Bonding",
      "Sustainability",
      "Values / Code of Conduct",
      "Wellness",
      "Customer Centricity"
    ];

    if ((gameCategory?.data && gameCategory.data.length > 0) ||
      (gameObjectives?.data && gameObjectives.data.length > 0)) {
      const arr1 = gameCategory?.data || [];
      const arr2 = gameObjectives?.data || [];
      const filteredCategories = arr1.filter(obj => namesArray.includes(obj.title));
      const filteredObjectives = arr2.filter(obj => namesArray.includes(obj.title));
      const combinedArray = [
        ...filteredCategories,
        ...filteredObjectives
      ];
      setCategoriesAndObjectives(combinedArray);
      setGamesForActiveCategory(combinedArray[0]?.games);

    }
  }, [gameCategory, gameObjectives]);

  useEffect(() => {
    if (allSlogans) {
      const result = allSlogans.data.filter(slogan => slogan.sloganType === 'feature');
      setFilteredSlogans(result);
    }
  }, [allSlogans]);

  useEffect(() => {
    if (bannerGames) {
      setBanners(bannerGames);
    }
  }, [bannerGames]);



  const isImage = (url) => /\.(jpg|jpeg|png|gif)$/i.test(url);
  const [visible, setVisible] = useState(false);
  const [openDemoRequestModal, setOpenDemoRequestModal] = useState(false);
  const [openMessageModal, setopenMessageModal] = useState(false);
  const [openEmailExistModal, setopenEmailExistModal] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [loaded, setLoaded] = useState(true);
  const AllFeatureBanners = useSelector(state => state.featureBanners);
  const [featureBanners, setFeatureBanners] = useState([]);
  const [focusedIdx, setFocusedIdx] = React.useState(0);
  const [focusedIdxDynamicButton, setFocusedIdxDynamicButton] = React.useState('');
  useEffect(() => {
    setFeatureBanners(AllFeatureBanners?.featureBanner);
  }, [AllFeatureBanners]);

  useEffect(() => {
    const tabElements = document.querySelectorAll('.tab1');
    const glider = document.querySelector('.glider1');
    if (tabElements.length > 0 && glider) {
      const activeTabElement = tabElements[activeTab];
      glider.style.width = `${activeTabElement.offsetWidth}px`;
      glider.style.transform = `translateX(${activeTabElement.offsetLeft}px)`;
    }
  }, [activeTab]);

  const initialFormData = {
    firstname: "",
    lastname: "",
    email: "",
    companyname: "",
    comments: ""
  };
  const [phone, setPhone] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [isValidPhone, setIsValidPhone] = useState(false);
  const [isValidphoneNumber, setIsValidphoneNumber] = useState(false);
  const [googleReCaptchaToken, setGoogleReCaptchaToken] = useState("");
  const [formValues, setFormValues] = useState({
    firstname: "",
    lastname: "",
    email: "",
    companyname: "",
    comments: ""
  });
  const [signUpForm, setsignUpForm] = useState({
    email: "",
    firstname: "",
    lastname: "",
    orgname: "",
    password: ""
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.replace(/^\s+/, "");


    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: name == 'firstname' || name == 'lastname' ? trimmedValue.replace(/[^A-Za-z\s]/g, '') : trimmedValue
    }));
    if (touched[name]) {
      validateField(name, trimmedValue);
    }
    if (name == "email") {
      setPlanFormData(prevData => ({
        ...prevData,
        email: value
      }));
    }
    if (name == 'companyname') {
      setPlanFormData(prevData => ({
        ...prevData,
        companyName: value

      }));
    }
  };

  const handleChangeSignupForm = (e) => {
    const { name, value } = e.target;
    const trimmedValue = value.replace(/^\s+/, "");
    setsignUpForm((prevValues) => ({
      ...prevValues,
      [name]: name == 'firstname' || name == 'lastname' ? trimmedValue.replace(/[^A-Za-z\s]/g, '') : trimmedValue
    }));
  };


  const [errors, setErrors] = useState({
    firstname: "",
    lastname: "",
    email: "",
    companyname: "",
  });
  const [touched, setTouched] = useState({
    firstname: false,
    lastname: false,
    email: false,
    companyname: false,
  });
  const handleBlur = (e) => {
    const { name } = e.target;
    setTouched((prevTouched) => ({
      ...prevTouched,
      [name]: true
    }));

    if (name == 'phone') {
      errors[name] = validateField('phone', isValidPhone);
    }
    else {
      errors[name] = validateField(name, formValues[name]);
    }

  };
  const validateField = (name, value) => {
    let error = "";
    if (name === "firstname" && value.trimLeft() === "") {
      error = "Please fill out this field";
    }
    else if (name === "lastname" && value.trimLeft() === "") {
      error = "Please fill out this field";
    }
    else if (name === "email" && value.trimLeft() === "") {
      error = "Please fill out this field";
    }

    else if (name === "email" && !/^\S+@\S+\.\S+$/.test(value)) {
      error = "Invalid Email Address";
    } else if (name === "companyname" && value.trimLeft() === "") {
      error = "Please fill out this field";
    }
    else if (name === "phone" && phone == "") {
      error = "Please fill out this field";
    }
    else if (name === "phone" && !value) {

      error = "Invalid Phone Number";
    }
    return error;
  };

  const validateAllFields = () => {
    const newErrors = {
      firstname: validateField("firstname", formValues.firstname),
      lastname: validateField("lastname", formValues.lastname),
      email: validateField("email", formValues.email),
      companyname: validateField("companyname", formValues.companyname),
      phone: validateField("phone", isValidPhone)
    };
    return newErrors;
  };
  async function handleGoogleCaptcha(value) {
    if (value) {
      setGoogleReCaptchaToken(value);
    }
  }
  const gotoDemoForm = () => {
    sectionOneRef.current.scrollIntoView({ behavior: 'smooth' });
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newTouched = {
      firstname: true,
      lastname: true,
      email: true,
      companyname: true,
      phone: true
    };

    setTouched(newTouched);
    const newErrors = validateAllFields();
    setErrors(newErrors);
    if (Object.values(newErrors).every((error) => error === "") && isValidPhone) {
      if (googleReCaptchaToken) {
        const response = await axios.post(
          BASE_URL + `/api/user/verify/googlecaptcha`,
          { token: googleReCaptchaToken }
        );

        if (response.data.data === true) {
          const demoRequestObj = {
            firstname: formValues.firstname,
            lastname: formValues.lastname,
            email: formValues.email,
            companyname: formValues.companyname,
            phone: phone,
            comments: formValues.comments
          };

          setLoaded(false);
          await axiosApiInstance
            .post(BASE_URL + "/api/demo-request-new/add", demoRequestObj)
            .then(async (response) => {
              if (response && response.status === 200) {
                setOpenDemoRequestModal(true);
                setFormValues({ firstname: "", lastname: "", email: "", companyname: "", comments: "" });
                setPhone("");
                setTouched({ firstname: false, lastname: false, email: false, companyname: false });
                setErrors({ firstname: "", lastname: "", email: "", companyname: "", phone: "" });
                setLoaded(true);
              }
            })
            .catch((error) => {
              failureAlert("Failed to add request");
              setLoaded(true);
            });
        } else {
          failureAlert("Please verify you are not a robot");
        }
      } else {
        failureAlert("Please verify you are not a robot");
      }
    }
  };

  const tabs1 = [
    { id: 0, label: "Play" },
    { id: 1, label: "Games" },
    { id: 2, label: "Live Events" }
  ];

  const [showWheel, setShowWheel] = useState(false);
  const [isSpinning, setIsSpinning] = useState(false);
  const [discountValue, setDiscountValue] = useState(null);
  const [isDiscountSet, setIsDiscountSet] = useState(false);

  useEffect(() => {
    const discountR = sessionStorage.getItem('discount');
    if (discountR == null || discountR == 'null' || discountR.trim() == '') {
      Isset(true);
      setIsDiscountSet(false)
    }
    else {
      Isset(false);
      setIsDiscountSet(true)
    }
  }, [discountValue]);

  const scrollToSection9 = () => {
    const section3 = document.getElementById('section9');
    if (section3) {
      window.scrollTo({
        top: section3.offsetTop - 72, // 72px is the height of the header
        behavior: 'smooth',
      });
    }
  };
  const handleInputChange = (name, value) => {
    if (name === 'companyName') {
      setFormValues(prevValues => ({
        ...prevValues,
        companyname: value
      }));
      // setCompanyName(value);
    } else if (name === 'email') {
      setFormValues(prevValues => ({
        ...prevValues,
        email: value,  // Set the new email
      }));
      // setEmail(value);
    }
  };
  const handleTabChange = (id, index) => {
    const filteredObjects = categorylist.filter(obj => obj.id === id);
    setCarouselKey(prevKey => prevKey + 1);
    setloading(false)
    setGamesForActiveCategory(filteredObjects[0].games);
    setFocusedIdxDynamicButton(filteredObjects[0]?.id);
    setloading(true)
  };

  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const handlePlayClick = () => {
    setIsPlaying(true);
    if (videoRef.current) {
      videoRef.current.play();
    }
  };

  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const playFaq = [
    { title: 'What is ExtraMile Play?', content: "ExtraMile Play caters to every kind of team—whether remote, offline, online, big, small, or somewhere in between, we've got the perfect adventure waiting for you!" },
    { title: 'How do I sign up for ExtraMile Play?', content: 'Signing up is as easy as a high-five! Just visit our website, choose your plan, and dive into a world where team spirit meets limitless fun.' },
    {
      title: "Can I play a game without subscribing to the platform?",
      content: "Yes, you can! Feel free to try out any game and play up to stage 1 to experience the fun and excitement firsthand before deciding to subscribe. It's the perfect way to get a feel for what ExtraMile Play has to offer!"
    },
    {
      title: " What subscription plans does ExtraMile Play offer?",
      content: "We offer flexible plans to suit every team's needs—whether you prefer monthly adventures or want to save with annual subscriptions, we've got options that fit your style of working!"
    }, {
      title: "Are there any discounts for annual subscriptions?",
      content: "Yes! Enjoy exclusive discounts when you opt for our annual subscription plans, making team-building with ExtraMile Play both rewarding and cost-effective."
    }
  ];

  const gamesFaq = [
    { title: 'What types of games are available on ExtraMile Play?', content: 'From brain-teasing puzzles to creative challenges and everything in between, ExtraMile Play offers a diverse range of games designed to spark joy and foster teamwork.' },
    { title: "Can we customise the games to fit our company's theme?", content: "Absolutely! Personalise your team's experience by customising games to reflect your company's culture, values, and goals—making each activity uniquely yours. The options are available here. " },
    { title: "How many players can participate in a game?", content: "Whether your team is small and intimate or large and dynamic, our games are crafted to accommodate all group sizes, ensuring everyone can join in on the fun! Know more about it here. " },
    { title: "Are the games suitable for remote teams?", content: "Yes! ExtraMile Play is perfect for remote teams, offering engaging activities that bridge distances and foster collaboration, no matter where your team members are located." },
  ];

  const liveEventsFaq = [
    { title: "Can I use the games to do LIVE events on Zoom, MS Teams etc?", content: "A 100% yes! Playing ExtraMile Play's games for LIVE events on Zoom, MS Teams, or any virtual platform is extremely easy. We believe in creating engaging experiences that bring teams together no matter where they are, ensuring every event is a hit!" },
    { title: "Are there facilitators available to help run the LIVE events?   ", content: "Yes! Our expert facilitators ensure your LIVE events run smoothly, guiding your team through engaging activities that enhance teamwork. When confirming the event, we share profiles of our experts for you to choose from, providing the perfect fit to help run your event." },
  ];
  let accordionItems;
  switch (focusedIdx) {
    case 1:
      accordionItems = gamesFaq;
      break;
    case 2:
      accordionItems = liveEventsFaq;
      break;
    default:
      accordionItems = playFaq;
  }

  const [showSpinner, setShowSpinner] = useState(false);
  const handleValueChange2 = (value) => {
    setDiscountValue(value);
    sessionStorage.setItem('discount', value);
  };

  const hideImage = () => {
    setShowImage(false);
    setopenMessageModal(true);
    setGif(false);
    setShowGif(false);

  };
  useEffect(() => {
    const handleScroll = () => {
      const section = document.getElementById('section10');
      if (section) {
        const rect = section.getBoundingClientRect();
        if (rect.top < window.innerHeight && rect.bottom >= 0) {
          setVisible(true);
        } else {
          setVisible(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll();
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  const [activeIndex, setActiveIndex] = useState(0);

  const handleButtonClick = (index) => {
    setActiveIndex(index);
  };
  const CarouselItem = () => (
    <div className="flex  items-center p-4 bg-white shadow-lg rounded-lg w-100 flex-col">
      <img src="/path-to-logo.png" alt="Logo" className="mb-4 w-24 h-24" />
      <h2 className="text-2xl font-bold mb-2">Title</h2>
      <h3 className="text-xl font-semibold mb-2">Designation</h3>
      <p className="text-center mb-4">This is a paragraph that provides additional information about the item. It is aligned vertically within the carousel item.</p>
    </div>
  );
  const Card = ({ position, transform, rotation = 0, label, children, initialZIndex, top, rotationhover = 0, hoverRotation = 10, backgroundImage, gameId, maxPlayers }) => {
    const [hovered, setHovered] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false);

    useEffect(() => {
      let timer;
      if (hovered) {
        timer = setTimeout(() => setShowOverlay(true), 1000);
      } else {
        clearTimeout(timer);
        setShowOverlay(false);
      }
      return () => clearTimeout(timer);
    }, [hovered]);
    const handleClickMobilecard = () => {
      
      setHovered(prevState => !prevState);
    };

    
    return (
      <div
        className={`absolute  p-6 rounded shadow-lg flex flex-col items-center justify-center
        w-[50vw] h-[24vh]
        transition-transform duration-300 ease-in-out transform`}
        style={{
          transform: hovered
            ? `translate(${transform.x}%, ${transform.y}%) rotate(${rotation + hoverRotation}deg)`
            : `translate(${position.x}%, ${position.y}%) rotate(${rotation}deg)`,
          borderRadius: '25px',
          zIndex: hovered ? 21 : initialZIndex,
          scale: hovered ? 5 : 1,
          overflow: hovered ? 'hidden' : 'visible',
        }}
        onClick={handleClickMobilecard}
      >
        <img
          src={backgroundImage}
          className="absolute inset-0 w-full h-full  rounded"
          style={{ borderRadius: '25px' }}
        />

        <div
          className={`absolute inset-0 flex flex-col items-center justify-center bg-black bg-opacity-50 rounded transition-opacity duration-300
          ${hovered ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
          style={{
            borderRadius: '25px',
            bottom: top,
            left: 0,
            zIndex: 10,
          }}
        >
          {/* <div  style={{ borderRadius: '6px!important' ,position:'absolute',top:'30%'}}> */}
          <Link
            to={`/game-detail/${gameId}`}
            className="bg-orange-500 text-white px-4 py-1 rounded"
            style={{ borderRadius: '6px!important' }}
          >
            Play
          </Link>
          {/* <span className="text-white mt-2 text-lg">{maxPlayers} players</span> */}
        </div>

        {/* </div> */}
      </div>

    );
  };
  const cardData = [
    { position: { x: 0, y: -4 }, transform: { x: 0, y: 0 }, rotation: 0, label: "Center", zIndex: 20, backgroundImage: img1, rotationHover: 0, top: '20%' },
    { position: { x: 17, y: 10 }, transform: { x: 30, y: 0 }, rotation: 9, label: "Right Card 1", zIndex: 19, backgroundImage: img1, rotationHover: -7, top: '20%' },
    { position: { x: 28, y: 20 }, transform: { x: 38, y: 0 }, rotation: 15, label: "Right Card 2", zIndex: 17, backgroundImage: img1, rotationHover: -15, top: '20%' },
    { position: { x: -17, y: 10 }, transform: { x: -30, y: 0 }, rotation: -9, label: "Left Card 1", zIndex: 19, backgroundImage: bg, rotationHover: 7, top: '20%' },
    { position: { x: -28, y: 20 }, transform: { x: -38, y: 0 }, rotation: -15, label: "Left Card 2", zIndex: 17, backgroundImage: bg, rotationHover: 15, top: '20%' }
  ];
  const CardSection = () => (
    <div className="py-6 p-4 z-6 bg-white relative" style={{ zIndex: 33 }}>
      {/* <div class="img-container mt-0 pb-5 mb-4 mt-4">
        <span class="relative block">
          <img src={headingIcon} class="absolute -top-[52%] -left-[10px] w-6 h-6" alt="Image" />
          Unlock the Power of <span className="highligtSpan">&nbsp; ExtraMilePlay </span>
        </span>
      </div> */}
      <div className="relative flex justify-center md:justify-start mt-0 sm:mt-0 md:mt-12 ml-0 lg:mt-12  lg:ml-12">




        <h2 className="text-center relative inline-block mb-3 text-[18px] md:text-[40px] font-bold lg:ml-12 mb-8">
          <img
            src={headingIconGreen}
            alt="Decoration"
            className="absolute top-[-10px] left-[-24px] w-[25px] h-[23px] object-contain"
          />
          Unlock the Power of <span className="highligtSpan"> ExtraMilePlay</span>

        </h2>



      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10">
        <div className="relative bg-white rounded-lg shadowMobileCard p-6 w-full flex flex-col">
          <div className="absolute  top-[-20px] right-4 w-11 h-11 image-above-card">
            <img src={flexible} alt="Card Image" className="w-full h-full object-cover" />
          </div>
          <h2 className="text-lg font-bold mb-2 font-16">Flexibility <br /> <span className="highligtSpan">No team is too big or too small</span></h2>
          <p className="text-md  text-left font-grey">Our platform adapts seamlessly to remote, or hybrid workplaces, ensuring
            everyone stays connected and engaged - irrespective of the team size</p>
        </div>
        <div className="relative bg-white rounded-lg shadowMobileCard p-6 w-full flex flex-col">
          <div className="absolute  top-[-20px] right-4 w-14 h-14 image-above-card">
            <img src={InnovativeGames} alt="Card Image" className="w-full h-full object-cover" />
          </div>
          <h2 className="text-lg font-bold mb-2 font-16">Highly Engaging <br /><span className="highligtSpan">Say hello to unique team activities</span></h2>
          <p className="text-md  text-left font-grey">From brain-teasing puzzles to pulse-pounding challenges, each experience boosts
            communication and strengthens team spirit</p>
        </div>
        <div className="relative bg-white rounded-lg shadowMobileCard p-6 w-full flex flex-col">
          <div className="absolute  top-[-20px] right-4 w-12 h-12 image-above-card">
            <img src={customised} alt="Card Image" className="w-full h-full object-cover" />
          </div>
          <h2 className="text-lg font-bold mb-2 font-16">Tailored Experiences <br /><span className="highligtSpan">Your team, your goals, our expertise</span></h2>
          <p className="text-md text-left font-grey">Customise experiences to fit your needs and objectives with your preferred content in our games and wrappers</p>
        </div>
        <div className="relative bg-white rounded-lg shadowMobileCard p-6 w-full flex flex-col">
          <div className="absolute  top-[-20px] right-4 w-12 h-12 image-above-card">
            <img src={seamless} alt="Card Image" className="w-full h-full object-cover" />
          </div>
          <h2 className="text-lg font-bold mb-2 font-16">Seamless Integration <br /><span className="highligtSpan">Enjoy single sign-on with your preferred platform</span></h2>
          <p className="text-md  text-left font-grey">Our games seamlessly connect with your favourite tools, such as Slack, Microsoft
            Teams, and your HRMS or LMS –making teamwork smoother and more efficient than
            ever</p>
        </div>
        <div className="relative bg-white rounded-lg shadowMobileCard p-6 w-full flex flex-col">
          <div className="absolute  top-[-20px] right-4 w-12 h-12 image-above-card">
            <img src={impact} alt="Card Image" className="w-full h-full object-cover" />
          </div>
          <h2 className="text-lg font-bold mb-2 font-16">Data-Driven Insights <br /> <span className="highligtSpan">Actionable insights on engagement</span></h2>
          <p className="text-md  text-left font-grey">Gage your engagement levels across camaraderie, pride, trust and communication
            Our dashboards let you track collaboration, measure engagement, and boost your team's camaraderie with data</p>
        </div>
      </div>
    </div>
  );
  const Card2 = ({ title, paragraph, imageSrc }) => (
    <div className="relative bg-white rounded-lg shadowMobileCard p-6 w-full flex flex-col">
      <div className="absolute  top-[-20px] right-4 w-14 h-14 image-above-card">
        <img src={imageSrc} alt="Card Image" className="w-full h-full object-cover" />
      </div>
      <h2 className="text-lg font-bold mb-2 font-16">{title}</h2>
      <p className="text-md  text-justify font-grey">{paragraph}</p>
    </div>
  );
  const [activeIndex1, setActiveIndex1] = useState(null);
  const handleButtonClick1 = (index) => {
    setActiveIndex1(index);
  };
  const responsive4 = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1,
    },
  };
  const [isHovered3, setIsHovered3] = useState(false);
  const videoRef1 = useRef(null);

  const handleVideoEnd1 = () => {
    if (videoRef1.current) {
      videoRef1.current.currentTime = 0;
      videoRef1.current.play();
    }
  };
  const [totalScore, setTotalScore] = useState(0);

  const handleScore = (score) => {
    // alert(score)
    sessionStorage.setItem('discount', score);
    setTotalScore(prevScore => prevScore + score);
    setShowImage(true);
  };
  return (
    <div>
      {
        openEmailExistModal &&
        <EmailExistModal modalid={"email-exist-modal"} toggle={openEmailExistModal} setopenEmailExistModal={setopenEmailExistModal} />
      }
      {
        openMessageModal &&
        <MessageModal modalid={"message-modal"} toggle={openMessageModal} setopenMessageModal={setopenMessageModal} />
      }
      {
        openDemoRequestModal &&
        <DemoRequestModal modalid={"demo-request-modal"} toggle={openDemoRequestModal} setOpenDemoRequestModal={setOpenDemoRequestModal} />
      }
      <ToastContainer position='bottom-center' />
      <LoadingComponent loaded={loaded} />

      <div className="nonlogin-landing-section">
        <main className="main-content">
          <div className="section section1 ">
            <div className="image-container desktopView">
              <Carousel responsive={mainBanner}>
                {featureBanners.map((game, index) => {
                  const bannerUrl = isMobile ? game.mobileBanner : game.desktopBanner;
                  const isImage = (banner) => { return banner.endsWith('.jpg') || banner.endsWith('.png'); };
                  return (
                    <div className="carousel-games-item" key={index}>
                      {isImage(bannerUrl) ? (<img src={S3_BASE_URL + bannerUrl} className="w-full h-full" />)
                        : (
                          <video
                            ref={videoRef1}
                            className="video-banner"
                            fetchpriority="high"
                            autoPlay
                            muted
                            disablePictureInPicture
                            playsInline
                            id="video_banner"
                            onEnded={handleVideoEnd1}
                            width="600"
                            controls >
                            <source src={S3_BASE_URL + bannerUrl} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        )}
                    </div>
                  );
                })}
              </Carousel>
              <div className="content-container">
                <h1 className="title desktopView">
                  New Age Team Building Experiences <br className="break" /> for
                  <div className="words">
                    <span className="word mr-2 ml-2"> remote </span>
                    <span className="word">onsite</span>
                    <span className="word">hybrid</span>
                  </div>
                  dispersed teams
                </h1>
                <p className="paragraph">  Experiences that engage, excite and bond teams together</p>
                <div className="button-group">
                  <button
                    className="rocket-button mr-4"
                    onClick={handleButtonClick6}
                    disabled={isAnimating}
                  >
                    Request Demo
                    {isAnimating ? (
                      <div className="fly-container">
                        <img src={rocketRedquestDemo} className="fly-image" alt="Flying Rocket" />
                      </div>
                    ) : (
                      <div className="rocket"></div>
                    )}
                  </button>

                  <button className="btn transparent " style={{ margin: '0px!important' }} onClick={onButtonClick}>Explore Now</button>
                </div>
                <div className="desktopView">
                  <GameSection gameData={filteredSlogans} onBtnClick={onBtnClick} onScrollToSection={(section) => {
                    if (section === 'sectionOne') {
                      scrollToSection(sectionOneRef);
                    } else if (section === 'sectionTwo') {
                      scrollToSection(sectionTwoRef);
                    }
                  }} />
                </div>
              </div>
            </div>

            <div className="mobileView" style={{ width: '100%' }}>
              <div className="relative w-full h-[calc(62vh)] flex items-center justify-center">
                <Carousel responsive={responsive} className="w-full h-full">
                  {featureBanners.map((game, index) => {
                    const bannerUrl = isMobile ? game.mobileBanner : game.desktopBanner;
                    return (
                      <div className=" w-full h-full flex items-center justify-center" key={index}>
                        {isImage(bannerUrl) ? (
                          <img
                            src={S3_BASE_URL + bannerUrl}
                            alt={game.alt}
                            className="object-cover w-full h-[calc(80vh)]"
                          />
                        ) : (
                          <video
                            ref={videoRef1}
                            className="video-banner"
                            fetchpriority="high"
                            autoPlay
                            muted
                            disablePictureInPicture
                            playsInline
                            id="video_banner"
                            onEnded={handleVideoEnd1}
                            controls
                          // className="w-full h-[calc(80vh)] object-cover"
                          >
                            <source src={S3_BASE_URL + bannerUrl} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        )}

                        {/* Text and Button positioned at the bottom */}

                      </div>
                    );
                  })}
                </Carousel>
                <div className="absolute bottom-[-10vh] left-0 w-full text-white text-center">
                  <h2 className="font-bold text-white mb-4" style={{ fontSize: '20px' }}>
                    New Age Team Building Experiences for&nbsp;
                    <div className="wordsz mt-0">
                      <h2 className="wordz font-bold mr-2 ml-2">remote</h2>
                      <h2 className="wordz font-bold">onsite</h2>
                      <h2 className="wordz font-bold">hybrid</h2>
                    </div>
                    dispersed teams
                  </h2>
                  <p className="mb-4 paragraph" style={{ fontSize: '16px' }}> Experiences that engage, excite and bond teams together</p> {/* Paragraph */}

                  {/* Wrapper for Button and Card Containers */}
                  <div className="flex flex-col items-center">
                    {/* Button Container with Two Inline Buttons */}
                    <div className="flex justify-center space-x-4 mb-0"> {/* Adjust margin-bottom to 0 */}
                      <button className="flex items-center text-white  py-2 rounded-md hover:bg-red-700 redbtnHomePage" onClick={scrollToSection9}>
                        Request Demo
                        <img src={rocketRedquestDemo} alt="Demo Icon" className="mr-2 w-4 h-4" /> {/* Adjust width and height as needed */}

                      </button>
                      <button onClick={onButtonClick} className="bg-transparent border-2 border-white text-white px-6 py-2 rounded-md hover:bg-white hover:text-black transparentbtnHomePage">
                        Explore Now
                      </button>
                    </div>

                    {/* 5 Cards - One Center, Two Left, Two Right */}
                    <div className="flex justify-center items-center mt-0 relative"> {/* Adjust margin-top to 0 */}
                      <div className="flex justify-center items-center sm:w-[80vw] h-[30vh]">
                        {cardData.map((card, index) => (
                          <Card
                            key={index}
                            position={card.position}
                            transform={card.transform}
                            rotation={card.rotation}
                            label={card.label}
                            zIndex={card.zIndex}
                            className="group"
                            top={card.top}
                            hoverRotation={card.rotationHover}
                            initialZIndex={card.zIndex}
                            backgroundImage={S3_BASE_URL + filteredSlogans[0]?.games[index]?.coverMedia[1]}
                            gameId={filteredSlogans[0]?.games[index]?.id}
                            maxPlayers={filteredSlogans[0]?.games[index]?.maxPlayers}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>



              </div>

            </div>
          </div>


          <section className="section section2 gradient"  >
            <div className="row desktopView">
              <div className="card-container2">
                <div className="card2 removeBgCenterText">
                  <div className="img-container ">
                    <div className="titleFontSize">
                      Unlock the power of <div className="highligtSpan2">ExtraMilePlay</div>
                    </div>
                  </div>
                </div>
                <div className="card2">
                  <img className="card-image" src={flexible} alt="Card Image 3" />
                  <h1>Flexibility</h1>
                  <span className="highlightCardSpan">No team is too big or too small</span>
                  <p> Our platform adapts seamlessly to remote, or hybrid workplaces, ensuring
                    everyone stays connected and engaged - irrespective of the team size</p>
                </div>
                <div className="card2">
                  <img className="card-image" src={InnovativeGames} alt="Card Image 2" />
                  <h1>Highly Engaging </h1>
                  <span className="highlightCardSpan">Say hello to unique team activities</span>
                  <p>From brain-teasing puzzles to pulse-pounding challenges, each experience boosts
                    communication and strengthens team spirit</p>
                </div>
                <div className="card2">
                  <img className="card-image" src={customised} alt="Card Image 4" />
                  <h1>Tailored Experiences </h1>
                  <span className="highlightCardSpan">Your team, your goals, our expertise</span>
                  <p>Customise experiences to fit your needs and objectives with your preferred content in our games and wrappers </p>
                </div>
                <div className="card2">
                  <img className="card-image" src={seamless} alt="Card Image 1" />
                  <h1>Seamless Integration  </h1>
                  <span className="highlightCardSpan"> Enjoy single sign-on with your preferred platform</span>
                  <p>  Our games seamlessly connect with your favourite tools, such as Slack, Microsoft
                    Teams, and your HRMS or LMS –making teamwork smoother and more efficient than
                    ever</p>
                </div>
                <div className="card2">
                  <img className="card-image" src={impact} alt="Card Image 6" />
                  <h1>Data-Driven Insights  </h1>
                  <span className="highlightCardSpan">Actionable insights on engagement </span>
                  <p> Gage your engagement levels across camaraderie, pride, trust and communication
                    Our dashboards let you track collaboration, measure engagement, and boost your team's camaraderie with data</p>
                </div>
              </div>
            </div>
            <div className="mobileView"> <CardSection /></div>
          </section>
          <div className="gradient1">
            <section className=" flex flex-col items-center justify-center text-center section section3 ">
              {/* <div class="img-container mt-0 pb-5 mb-4 mt-8 md:mt-0">
                <span class="relative block">
                  <img src={headingIcon} class="absolute custom-position w-6 h-6" alt="Image" />
                  <span className="highligtSpan">  Most played </span> &nbsp;games by others
                </span>
              </div> */}
              <div className="text-center relative mb-6 lg:ml-12 ">
                {/* Mobile Section */}
                <h2 className="block text-[19px] md:hidden font-bold mb-2 mt-6">
                  <img
                    src={headingIcon}
                    alt="Decoration"
                    className="absolute top-[14px] left-[-30px] w-[25px] h-[23px] object-contain"
                  />
                  <span className="highlightSpan">   Most played </span> games by others
                </h2>

                {/* Desktop Section */}
                <h2 className="hidden md:block text-[40px] font-bold mb-3">
                  <img
                    src={headingIcon}
                    alt="Decoration"
                    className="absolute top-[-10px] left-[-30px] w-[25px] h-[23px] object-contain"
                  />
                  <span className="highlightSpan">   Most played </span> games by others
                </h2>
              </div>

              <div className="btnstyle  whitespace-nowrap flex desktopView" >  {categorylist && categorylist.length > 0 &&
                <DynamicButtonsTabs value={focusedIdxDynamicButton} onChange={handleTabChange} tabs={categorylist} />
              }</div>
              {categorylist && categorylist.length > 0 && (<div className="mobileView button-containergames mb-4">
                {categorylist.map((label, index) => (
                  <button
                    key={index}
                    className={`buttonGames ${focusedIdxDynamicButton === label.id ? 'active' : ''}`}
                    onClick={() => handleTabChange(label.id)}>
                    {label.title}
                  </button>
                ))}
              </div>)}
              <Carousel responsive={responsive23} key={carouselKey}>
                {categoryWiseGames?.length > 0 && categoryWiseGames.map((game, index) => (
                  <div className="carousel-games-item group relative" key={index}>
                    <div className="games-item relative">
                      <img src={S3_BASE_URL + game.coverMedia[1]} alt="" className="rounded-md w-full h-full object-cover" />
                      <div className="overlay absolute inset-0 flex items-center justify-center">
                        <div className="button-group flex items-center justify-center">
                          <Link
                            to={`/game-detail/${game.id}`}
                            className="button-play bg-orange-500 text-white py-2 px-4 rounded-md opacity-0 group-hover:opacity-100 transition-opacity duration-300"
                          >
                            Play
                          </Link>
                        </div>
                      </div>
                    </div>
                    <h2 className="card-title-1">{game?.title}</h2>
                    {/* <h2 className="card-title-2">{game?.maxPlayers} Players</h2> */}
                  </div>
                ))}

              </Carousel>
              <div className="view-all-games">
                <Link to={`/games-all`} className="centered-button btn   btnRed mb-6 sm:mb-0">
                  View All Games
                </Link>
              </div>
            </section>
            <section className="section section4 ">
              <div className="games-elevate-section">
                <div className="left-side">
                  <h2 className="mt-12 md:mt-0">
                    Discover How <span className="text-highlight">Extramile Elevates </span><br /> Engagement
                  </h2>
                </div>
                <div className="right-side">
                  <h3 className="right-title">
                    Your default choice for team building
                  </h3>
                  <p className="right-description">
                    Our platform democratises engagement by making it inclusive, consistent and
                    accessible to any Manager
                  </p>
                </div>
              </div>
              <div className="image-container">
                <div className="video-wrapper w-full h-full lg:h-full sm:h-full">
                  {/* Mobile Image */}
                  <img
                    src={mobileImg}
                    alt="Mobile Image"
                    className="block sm:hidden w-[95%] h-full object-cover mx-auto mobileView"
                  />
                  {/* Desktop Image */}
                  <img
                    src={desktopImg}
                    alt="Desktop Image"
                    className="hidden sm:block w-full h-full object-cover desktopView"
                  />
                </div>
              </div>
            </section>
            <section className="section section5">
              <Statistics style={{ marginTop: '2rem', marginBottom: '2rem' }} statisticsData={statisticsData} />
            </section>
          </div>
          <section className="section section6 " >
            <div className="left-section desktopView">
              <div className="parent relative">
                <div class="circle-shadow"></div>
                <img className="image1 " src={img1} alt="Top Right Image" />
                <img className="image2" src={img2} alt="Bottom Left Image" />
              </div>
            </div>
            <div class="relative sm:hidden mt-[1rem] mb-[1rem]">
              <div class="relative z-20 top-0   right-[-20%]">
                <img src={img1} alt="Bottom Image" class="w-[80%] h-auto" />
              </div>
              <div class="absolute bottom-0 left-0 z-0">
                <img src={img2} alt="Top Image" class="w-[60%] h-auto" />
              </div>
            </div>
            <div className="right-section desktopView">
              <h2>
                Watch how teams <span className="text-highlight">collaborate and communicate</span> better across borders
              </h2>
              <h3>
                Breaking boundaries and building bridges
              </h3>
              <p>No matter the distance, we have experiences to connect teams through non-transactional conversations</p>
              <div className=" games-grid-items-container">
                <div className="explore_card">
                  <img src={liveengagement} alt="Icon 1" className="card-icon" />
                  <span className="card-title">Catering to every team size</span>
                </div>
                <div className="explore_card">
                  <img src={explorecreativity} alt="Icon 2" className="card-icon" />
                  <span className="card-title">Real-time team bonding</span>
                </div>
                <div className="explore_card">
                  <img src={customisegames} alt="Icon 3" className="card-icon" />
                  <span className="card-title">Supports online, offline and hybrid setup</span>
                </div>
              </div>
              <div className="explore-more">
                <Link to={`/plans`} className="btn black">
                  <span style={{ marginRight: '10px' }}>Explore Our Plans</span>
                  <img src={exploreMoreIcon} alt="Icon" class="btn-icon" />
                </Link>
              </div>
            </div>
            <div className="right-section mobileView block sm:hidden">
              <h2>
                Watch how teams <span className="text-highlight">collaborate and communicate</span> better across borders
              </h2>
              <h3>Breaking boundaries and building bridges</h3>
              <p>No matter the distance, we have experiences to connect teams through non-transactional conversations</p>
              <div className="games-grid-items-container flex flex-col items-center">
                <div className="flex justify-center w-full gap-4 mb-4">
                  <div className="explore_card w-1/2 flex flex-col items-center">
                    <img src={liveengagement} alt="Icon 1" className="card-icon" />
                    <span className="card-title">Catering to every team size</span>
                  </div>
                  <div className="explore_card w-1/2 flex flex-col items-center">
                    <img src={explorecreativity} alt="Icon 2" className="card-icon" />
                    <span className="card-title">Real-time team bonding</span>
                  </div>
                </div>
                <div className="flex justify-center w-full">
                  <div className="explore_card w-1/2 flex flex-col items-center">
                    <img src={customisegames} alt="Icon 3" className="card-icon" />
                    <span className="card-title">Supports online, offline and hybrid setup</span>
                  </div>
                </div>
              </div>
              <div className="explore-more mt-4 flex justify-center">
                <Link to={`/plans`} className="btn black">
                  <span style={{ marginRight: '10px' }}>Explore Our Plans</span>
                  <img src={exploreMoreIcon} alt="Icon" class="btn-icon" />
                </Link>
              </div>
            </div>
          </section>
          <div className="gradient3">
            <section className="section section7">
              {/* <div className="img-container mt-12 sm:pb-0">
                <span className="relative block">
                  <img
                    src={headingIconGreen}
                    className="absolute top-[-15px] left-[-33px] w-6 h-6"
                    alt="Image"
                  />
                  Teams who love us
                </span>
              </div> */}
              <div className="text-center relative mb-0 lg:ml-12">
                {/* Mobile Section */}
                <h2 className="block text-[19px] md:hidden font-bold mb-0">
                  <img
                    src={headingIconGreen}
                    alt="Decoration"
                    className="absolute top-[-10px] left-[-30px] w-[25px] h-[23px] object-contain"
                  />
                  Teams who love us
                </h2>

                {/* Desktop Section */}
                <h2 className="hidden md:block text-[40px] font-bold mb-0">
                  <img
                    src={headingIconGreen}
                    alt="Decoration"
                    className="absolute top-[-10px] left-[-30px] w-[25px] h-[23px] object-contain"
                  />
                  Teams who love us
                </h2>
              </div>
              <Carousel
                autoPlay
                additionalTransfrom={0}
                arrows={false}
                autoPlaySpeed={3000}
                centerMode={false}
                className=""
                containerClass="container-with-dots"
                dotListClass=""
                draggable
                focusOnSelect={false}
                infinite
                itemClass=""
                keyBoardControl
                minimumTouchDrag={80}
                pauseOnHover
                renderArrowsWhenDisabled={false}
                renderButtonGroupOutside={false}
                renderDotsOutside={false}
                responsive={{
                  desktop: {
                    breakpoint: {
                      max: 3000,
                      min: 1024
                    },
                    items: 6,
                    partialVisibilityGutter: 40
                  },
                  mobile: {
                    breakpoint: {
                      max: 464,
                      min: 0
                    },
                    items: 2,
                    partialVisibilityGutter: 30
                  },
                  tablet: {
                    breakpoint: {
                      max: 1024,
                      min: 464
                    },
                    items: 2,
                    partialVisibilityGutter: 30
                  }
                }}
                rewind={false}
                rewindWithAnimation={false}
                rtl={false}
                shouldResetAutoplay
                showDots={false}
                sliderClass=""
                slidesToSlide={1}
                swipeable
              >
                {allOrgLogos.map((logo, index) => (
                  <div
                    key={index}
                    className="mt-6 mb-8 lg:mt-12 lg:mb-12 flex justify-center"
                  >

                    <img
                      src={`${S3_BASE_URL}${logo.path}`}
                      alt={`Logo ${index}`}
                      className="w-[100px] h-[100px] object-contain lg:w-[150px] lg:h-[150px]"
                    />
                  </div>
                ))}
              </Carousel>

              {/* <div class="img-container mt-12 lg:pb-12">
                <span class="relative block">
                  <img src={headingIcon} class="absolute -top-[50%] -left-[6%] w-6 h-6" alt="Image" />
                  Here’s how we make a difference
                </span>
              </div> */}
              <div className="text-center relative mb-6 lg:ml-12">
                {/* Mobile Section */}
                <h2 className="block text-[18px] md:hidden font-bold mb-3">
                  <img
                    src={headingIcon}
                    alt="Decoration"
                    className="absolute top-[-10px] left-[-30px] w-[25px] h-[23px] object-contain"
                  />
                  Here’s how we make a difference
                </h2>

                {/* Desktop Section */}
                <h2 className="hidden md:block text-[40px] font-bold mb-3">
                  <img
                    src={headingIcon}
                    alt="Decoration"
                    className="absolute top-[-10px] left-[-30px] w-[25px] h-[23px] object-contain"
                  />
                  Here’s how we make a difference
                </h2>
              </div>
              {userTestimonial && userTestimonial.length >= 5 && <TestimonialCarousel images={userTestimonial} />}</section>

            <div className="mobileCarousel" style={{ marginBottom: '0rem', marginTop: 0 }}>
              <Carousel responsive={responsive4}
                autoPlay={true}
                autoPlaySpeed={10000}
                infinite
              >
                {userTestimonial.map((item, index) => (
                  <div key={index} className="carousel-item mt-4 mb-4">
                    <div className="flex flex-col items-center text-center p-5">
                      <div className="relative mb-4">
                        <img src={`${S3_BASE_URL}${item.path}`} alt="Image" className="w-32 h-32 object-cover rounded-full" />
                      </div>
                      <h1 className="text-xl font-bold mb-2" style={{ fontSize: '16px' }}>{item.Name}</h1>
                      <h2 className="text-xl font-bold mb-4" style={{ fontSize: '16px' }}>{item.Designation}</h2>
                      <p style={{
                        fontSize: '16px', textAlign: 'justify', color: '#4F4F4F'
                      }}>{item.Comment}</p>
                    </div>
                  </div>
                ))}
              </Carousel>
            </div>
          </div>



          <GenerateDiscount onInputChange={handleInputChange} onScore={handleScore}
            companyName={formValues.companyname}
            email={formValues.email} />
          {showImage && (
            <div>
              <ImageZoomOut showImage={showImage} discountValue={totalScore} onClose={hideImage} />
            </div>
          )}

          <div className="gradient4">
            <section className="section section9" id="section9">
              <div className="left-section">
                <form className="form">
                  <div className="form-row">
                    <div className="formGroup">
                      <input
                        type="text"
                        id="firstname"
                        name="firstname"
                        value={formValues.firstname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        placeholder="First Name"
                      />
                      {(touched.firstname || errors.firstname) && (
                        <div className="errmsg">{errors.firstname}</div>
                      )}
                    </div>
                    <div className="formGroup">
                      <input
                        type="text"
                        id="lastname"
                        name="lastname"
                        value={formValues.lastname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        placeholder="Last Name"
                      />
                      {(touched.lastname || errors.lastname) && (
                        <div className="errmsg">{errors.lastname}</div>
                      )}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="formGroup">
                      <input
                        type="text"
                        name="email"
                        value={formValues.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        placeholder="Email"
                      />
                      {(touched.email || errors.email) && (
                        <div className="errmsg">{errors.email}</div>
                      )}
                    </div>
                    <div className="formGroup">
                      <PhoneInput
                        className="formPhoneInput"
                        type="numbers"
                        placeholder="Enter phone number"
                        country="in"
                        onBlur={handleBlur}
                        inputProps={{
                          name: 'phone',
                          id: 'phone'
                        }}
                        onChange={(val, country, e, formattedValue) => {
                          if (
                            country &&
                            ((country.format &&
                              formattedValue &&
                              formattedValue.length === country.format.length) ||
                              val.length <= country.dialCode.length)
                          ) {
                            setIsValidPhone(true);
                            setPhone(val);
                          } else {
                            setIsValidPhone(false);
                            setPhone(val);
                          }
                        }}
                        value={phone}
                        id="phoneNo"
                      />
                      {(touched.phone || errors.phone) && (
                        <div className="errmsg">{errors.phone}</div>
                      )}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="formGroup">
                      <input
                        type="text"
                        name="companyname"
                        value={formValues.companyname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                        placeholder="Company Name"
                      />
                      {(touched.companyname || errors.companyname) && (
                        <div className="errmsg">{errors.companyname}</div>
                      )}
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="formGroup">
                      <textarea
                        id="message"
                        name="comments"
                        value={formValues.comments}
                        onChange={handleChange}
                        required
                        placeholder="Comments"
                      ></textarea>
                    </div>
                  </div>
                  <div className="form-row center">
                    <ReCAPTCHA
                      sitekey={GOOGLE_RECAPTCHA_SITE_KEY}
                      onChange={handleGoogleCaptcha}
                    />
                  </div>
                  <div className="form-row">
                    <div className="form-actions">
                      <button
                        type="button"
                        onClick={handleSubmit}
                        className="custom-btn"
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <div className="right-section">
                <h2>
                  Experience the new age of Teambuilding now,
                  <span className="text-highlight"> book a demo!</span>
                </h2>
                <p className="description">
                  This 15mins demo will give you an insight into making your teambuilding
                  consistent, inclusive and accessible to all, at affordable prices. Book your demo now!
                </p>
              </div>
            </section>
            <section className=" section10" id="section10">
              <div className="games-accordion-section">
                <div className="title-wrapper">
                  <div className="section-wrapper">
                    <div className="title-wrapper1">
                      <h2>
                        Frequently Asked<span className="text-highlight"> Questions </span>
                      </h2>
                      <p className="description">
                        Got questions? Check out our FAQs for all the info you may need or write to us if
                        we don’t answer them all.
                      </p>
                    </div>
                    <div className="explore-more sm:mb-12">
                      <Link to={`/faq`} className="btn black">
                        <span style={{ marginRight: '10px' }}>  Got Questions</span>
                        <img src={exploreMoreIcon} alt="Icon" class="btn-icon" />
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="image-wrapper">
                  <div className="button-group">
                    <div className="buttongrp" style={{ marginBottom: '1rem', width: '100%' }}>
                      <Tabs value={focusedIdx} onChange={setFocusedIdx} tabs={tabs1} />
                    </div>
                    <div className="accordion mt-[1rem]"><Accordion items={accordionItems} /></div>
                  </div>
                </div>
              </div>
            </section>

          </div>
        </main>
      </div>
      {visible && (
        <button className="back-to-top" onClick={scrollToTop}>
        </button>
      )}
    </div>
  );
};

export default React.memo(NonLoggedIn);