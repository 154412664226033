import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { axiosApiInstance, BASE_URL, failureAlert, IsAdmin, RAZOR_PAY_API_KEY, REG_EX_URL_FORMAT, S3_BASE_URL, successAlert } from "../../helpers/helper";
import { logOut } from "../../redux/actions/userAction";
import Header from "../../components/header/header";
import Footer from "../../components/footer/footer";
import play_happy from "../../assets/bookAnEvent-img/play-happy.png";
import calenderIcon from "../../assets/bookAnEvent-img/icon calender.png"
import "./BookAnEventForm.css"

import 'react-datepicker/dist/react-datepicker.css';
import Accordion from "../Accordion";

import DatePicker from "react-datepicker";
import { address } from "synonyms/dictionary";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import LoadingComponent from "../../components/loader/LoadingComponent";
import { getOrganisation } from "../../redux/actions/plansApiActions";
import EventPaymentSuccess from "../../components/modal/eventPaymentSuccess";

import attendeesIcon from "../../assets/bookAnEvent-img/attendees.png";
import virtualIcon from "../../assets/bookAnEvent-img/virtual event.png";
import calendarIcon from "../../assets/bookAnEvent-img/icon calender.png"
import PhoneInput from "react-phone-input-2";

import { FaUserFriends } from "react-icons/fa";
import { RiLiveLine } from "react-icons/ri";
// import { RiVideoOffLine } from "react-icons/ri";
import { SlCalender } from "react-icons/sl";




const BookAnEventForm1 = (props) => {

    const GetUser = useSelector(state => state.getUser);
    const { userInfo } = GetUser;
    const OrgDetailsByEmail = useSelector(state => state.getOrganisation);
    const { orgDetailsByEmail } = OrgDetailsByEmail;

    const [selectedOption, setSelectedOption] = useState(null);
    const [loaded, setLoaded] = useState(true)
    const [eventGameInfo, setEventGameInfo] = useState();
    const [eventTotalCost, setEventTotalCost] = useState(0);
    const [disabledDates, setDisableDates] = useState([]);
    const [disCountedAmount, setDisCountedAmount] = useState(0);
    const [discount, setDiscount] = useState(0);
    const [gstPerAmount, setGstPerAmount] = useState(0);
    const [GstAddedPrice, setGstAddedPrice] = useState(0)

    const [selectedCurrency, setSelectedCurrency] = useState("INR");
    const [openEventPaymentSuccess, setOpenEventPaymentSuccess] = useState(false)

    const [formDataTouch, setFormDataTouch] = useState({
        email: false,
        guestCount: false,
        phoneNo: false,
        clientFullName: false,
        orgName: false,
        address: false,
        startDate: false,
        startTime: false
    });
    const [formDate, setFormData] = useState({
        date: null,
        startTime: null,
        guestCount: null,
        email: null,
        clientFullName: null,
        orgName: null,
        phNo: null,
        payNow: null,
        payOffline: null,
        address: ''
    })
    const [validPhone, setValidPhone] = useState(false);
    const [defaultinputValue, setDefaultInputeValue] = useState({ phoneNo: "+91 99999-99999" })

    // const [selectedTime, setSelectedTime] = useState(null);


    const contents = [
        {
            title: "Refund And Cancellation Policy",
            content: `To be eligible for a refund, organizers must cancel or modify their reservation at least 72 hours before the event start time. A cancellation fee of ${selectedCurrency && selectedCurrency === "INR" ? 'Rs. 15,000' : '$150'} will be deducted from the total refund amount. For all cancellation or refund requests, organizers are required to contact us at contact@extramile.in.`
        },
        {
            title: "Rescheduling And Changes Policy",
            content: `Organizers may reschedule or change the event date or time free of charge if done at least 72 hours prior to the event start time. Rescheduling within 2 days of the event will incur a fee of ${selectedCurrency && selectedCurrency === "INR" ? 'Rs. 15,000' : '$150'}. Please note that changes to the number of participants are not allowed.`
        }
    ]

    const topDiv = useRef(null);
    const datePickerRef = useRef(null); // Create a ref for DatePicker

    const isAdmin = IsAdmin();
    const dispatch = useDispatch();
    const history = useHistory();

    const { gameId } = useParams();
    const { formId } = useParams();

    const scrollToDiv = (divRef) => {
        // Check if the ref exists and is not null
        if (divRef && divRef.current) {
            divRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };


    useEffect(() => {
        scrollToDiv(topDiv);
    }, []);


    useEffect(() => {
        const callBack = async () => {
            try {
                const response = await axios.get('https://api.ipgeolocation.io/ipgeo?apiKey=e1a29c31b72d4a4a9d0ce93be30400ee')
                if (response?.data?.country_name) {
                    if (response?.data?.country_name === "India")
                        setSelectedCurrency("INR");
                    else
                        setSelectedCurrency("USD");
                }
            } catch (err) {
                console.log(err);
            }
        }
        callBack();
    }, []);


    useEffect(() => {
        const callBack = async () => {
            setFormData({
                date: null,
                startTime: null,
                guestCount: null,
                email: null,
                // clientFullName: null,
                orgName: null,
                phNo: null,
                payNow: null,
                payOffline: null,
                address: ''
            })

            const response = await axiosApiInstance.get(BASE_URL + '/api/bookEvent/get-form/' + formId);
            if (response && response.data.message === 'form fetch sucessfully') {
                setFormData({
                    date: response.data.data.startDate,
                    startTime: response.data.data.startTime,
                    guestCount: response.data.data.guestCount,
                    email: response.data.data.email,
                    clientFullName: userInfo && userInfo.data ? `${userInfo.data?.firstName ? userInfo.data?.firstName : ''} ${userInfo?.data?.lastName ? userInfo.data?.lastName : ''}` : null,
                    orgName: response.data.data.companyName,
                    phNo: response.data.data.mobileNo,
                    // payNow: response.data.data.startDate,,
                    // payOffline: response.data.data.startDate,
                    address: response.data.data.address,
                })
                // if (response && response?.data?.data?.guestCount && eventGameInfo) {
                //     let value = response?.data?.data?.guestCount * Number(eventGameInfo?.costOfEvent[selectedCurrency]);
                //     setEventTotalCost(value)
                // }
                // if (response && eventGameInfo && eventGameInfo?.typeOfEvent === "online") {
                //     let value = Number(eventGameInfo?.costOfEvent[selectedCurrency]);
                //     setEventTotalCost(value)
                // }
                if (response && response?.data && response?.data?.data && response?.data?.data?.eventCost) {
                    // let value = Number(response?.data?.data?.eventCost) * Number(eventGameInfo?.costOfEvent[selectedCurrency]);
                    setEventTotalCost(response?.data?.data?.eventCost)
                }
            }
        }
        if (formId && formId !== '') {
            callBack()
        }
    }, [formId])

    useEffect(() => {
        if (userInfo && userInfo.data && userInfo.data?.email) {
            dispatch(getOrganisation(userInfo.data?.email));
            setFormData((preState) => ({
                ...preState,
                email: userInfo.data?.email,
                clientFullName: `${userInfo.data?.firstName ? userInfo.data?.firstName : ''} ${userInfo?.data?.lastName ? userInfo.data?.lastName : ''}`,
                // clientFullName: userInfo.data?.firstName,

            }))
        }

    }, [userInfo])
    // <input type="text" value={orgDetailsByEmail && orgDetailsByEmail.data && orgDetailsByEmail.data.name} className="form-control" disabled />
    useEffect(() => {
        if (userInfo && userInfo.data && orgDetailsByEmail && orgDetailsByEmail.data) {
            setFormData((preState) => ({
                ...preState,
                orgName: orgDetailsByEmail.data.name,
                phNo: orgDetailsByEmail.data.phoneNumber
            }))
        }

    }, [OrgDetailsByEmail, orgDetailsByEmail])

    useEffect(() => {
        const callBack = async () => {
            setEventGameInfo()
            const response = await axiosApiInstance.get(BASE_URL + '/api/bookEvent/event-game/' + gameId);
            if (response && response.data.message === 'get fetch sucessfully') {
                setEventGameInfo(response.data.data)
            }
        }
        if (gameId && gameId !== '') {
            callBack()
        }
    }, [gameId])


    useEffect(() => {
        const callBack = async () => {
            try {
                const response = await axios.get('https://api.ipgeolocation.io/ipgeo?apiKey=e1a29c31b72d4a4a9d0ce93be30400ee')
                if (response?.data?.country_name) {
                    if (response?.data?.country_name === "India")
                        setSelectedCurrency("INR");
                    else
                        setSelectedCurrency("USD");
                }
            } catch (err) {
                console.log(err);
            }
        }
        callBack();
        getAllBlockedDates()
    }, []);


    const handleFilterTab = () => {
        datePickerRef.current.setFocus();
    };

    const signOut = async () => {
        await dispatch(logOut());
        if (isAdmin) props.history.push("/admin");
        else props.history.push("/");
    };


    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    }

    const isDisabledDate = (date) => {
        // const disabledDates = [
        //     new Date(2024, 9, 17), // October 17, 2024
        //     new Date(2024, 9, 20), // October 20, 2024
        // ];
        const today = new Date();
        const twoDaysAfterToday = new Date(today);
        twoDaysAfterToday.setDate(today.getDate() + 2);

        const isWeekend = date.getDay() === 0 || date.getDay() === 6; // 0 is Sunday, 6 is Saturday

        const isSpecificDateDisabled = disabledDates.some(disabledDate =>
            date.getFullYear() === disabledDate.getFullYear() &&
            date.getMonth() === disabledDate.getMonth() &&
            date.getDate() === disabledDate.getDate()
        );

        const isCurrentOrNextTwoDays = (
            date.getFullYear() === today.getFullYear() &&
            date.getMonth() === today.getMonth() &&
            date.getDate() >= today.getDate() &&
            date.getDate() <= twoDaysAfterToday.getDate()
        );

        return !(isWeekend || isSpecificDateDisabled || isCurrentOrNextTwoDays);
    }

    const LoginValidateInput = (input) => {

        const errors = {
            // email: "",
            membersCount: "",
            phoneNo: '',
            address: '',
            orgName: '',
            clientFullName: '',
            startTime: '',
            date: ''

        };
        // if (formDataTouch.email && (!validateEmail(input.email) || input.email === " ")) {

        //     errors.email = "Please provide a valid email";
        // }
        if (formDataTouch.membersCount && input.guestCount <= 0) {
            errors.membersCount = "Invalid members count"
        }
        if (formDataTouch.membersCount && input.guestCount < eventGameInfo?.minPlayers) {
            errors.membersCount = "minimum participants must be " + eventGameInfo?.minPlayers
        }
        if (formDataTouch.membersCount && input.guestCount > eventGameInfo?.minPlayers && input.guestCount > eventGameInfo?.maxPlayers) {
            errors.membersCount = "maximum participants must be " + eventGameInfo?.maxPlayers
        }
        if (formDataTouch.phoneNo && input.phoneNo !== "" && !validPhone) {
            errors.phoneNo = "Invalid phone no"
        }
        if (formDataTouch.address && input.address === '' || formDataTouch.address && input.address === null) {
            errors.address = "Please enter company address"
        }
        else if (formDataTouch.address && input && input?.address && input.address.length < 4) {
            errors.address = "Please enter correct address"
        }

        if (formDataTouch.orgName && input.orgName === '' || formDataTouch.orgName && input.orgName === null) {
            errors.orgName = "Please enter company name"
        }
        else if (formDataTouch.orgName && input && input?.orgName && input.orgName.length <= 2) {
            errors.orgName = "Please enter valid company name"
        }

        if (formDataTouch.clientFullName && input.clientFullName === '' || formDataTouch.clientFullName && input.clientFullName === null) {
            errors.clientFullName = "Please enter your name"
        }
        else if (formDataTouch.clientFullName && input && input?.clientFullName && input.clientFullName.length <= 3) {
            errors.clientFullName = "name must contain more then 3 charater"
        }
        if (formDataTouch.startDate && input.date === '' || formDataTouch.startDate && input.date === null) {
            errors.date = "Please select event start date"
        }

        if (formDataTouch.startTime && input.startTime === '' || formDataTouch.startTime && input.startTime === null) {
            errors.startTime = "Please select event start time"
        }

        return errors;
    };

    let errorMessage = LoginValidateInput(formDate);


    useEffect(() => {
        // if (formDate && formDate.guestCount && eventGameInfo) {
        //     let value = formDate.guestCount * Number(eventGameInfo?.costOfEvent[selectedCurrency]);
        //     setEventTotalCost(value)
        // }

        if (formDate && eventGameInfo && eventGameInfo && eventGameInfo?.typeOfEvent === "online") {
            let value = Number(eventGameInfo?.costOfEvent[selectedCurrency]);
            setEventTotalCost(value)

            const eventPrice = value
            const gstPer = 18;
            const gstAmount = (eventPrice * gstPer) / 100;
            const priceAfterGst = eventPrice + gstAmount;
            setGstPerAmount(gstAmount);
            setGstAddedPrice(priceAfterGst);
        }
        else if (formDate && eventGameInfo && eventGameInfo && eventGameInfo?.typeOfEvent === "offline") {
            let value = Number(formDate.guestCount) * Number(eventGameInfo?.costOfEvent[selectedCurrency]);
            setEventTotalCost(value)

            const eventPrice = value
            const gstPer = 18;
            const gstAmount = (eventPrice * gstPer) / 100;
            const priceAfterGst = eventPrice + gstAmount;
            setGstPerAmount(gstAmount);
            setGstAddedPrice(priceAfterGst);
        }


    }, [formDate, eventTotalCost])

    useEffect(() => {

        if (formDate && formDate.payNow === "payNow" && eventGameInfo && eventGameInfo?.typeOfEvent === "online") {
            const originalPrice = Number(eventGameInfo?.costOfEvent[selectedCurrency]);
            const discountPercentage = 20;
            const discountAmount = (originalPrice * discountPercentage) / 100;
            const finalPrice = originalPrice - discountAmount;
            setDiscount(discountAmount);
            setDisCountedAmount(finalPrice);
            const eventPrice = finalPrice
            const gstPer = 18;
            const gstAmount = (eventPrice * gstPer) / 100;
            const priceAfterGst = eventPrice + gstAmount;
            setGstPerAmount(gstAmount);
            setGstAddedPrice(priceAfterGst);

        } else if (formDate && formDate.payNow === "payNow" && eventGameInfo && eventGameInfo?.typeOfEvent === "offline") {
            let value = Number(formDate.guestCount) * Number(eventGameInfo?.costOfEvent[selectedCurrency]);
            const originalPrice = Number(value);
            const discountPercentage = 20;
            const discountAmount = (originalPrice * discountPercentage) / 100;
            const finalPrice = originalPrice - discountAmount;
            setDiscount(discountAmount);
            setDisCountedAmount(finalPrice);
            const eventPrice = finalPrice
            const gstPer = 18;
            const gstAmount = (eventPrice * gstPer) / 100;
            const priceAfterGst = eventPrice + gstAmount;
            setGstPerAmount(gstAmount);
            setGstAddedPrice(priceAfterGst);

        }
        else {
            setDiscount(0);
            setDisCountedAmount(0);
        }
    }, [eventTotalCost, formDate]);


    const getAllBlockedDates = async () => {
        try {
            const response = await axiosApiInstance.get(BASE_URL + "/api/bookEvent/getBlockedDates");

            const result = JSON.parse(response?.data?.data[0].blockedDates);

            result.forEach(holiday => {
                const [year, month, day] = holiday.date.split('-');
                const holidayDate = new Date(year, month - 1, day);
                setDisableDates((prevState) => [...prevState, holidayDate]);
            });



        } catch (error) {
            console.error('Error fetching categories:', error);
        }
    };


    const generateTimeSlots = (start, end) => {
        const slots = [];
        let current = new Date(start);

        while (current <= end) {
            // Format the time with hours, minutes, AM/PM, and GMT offset
            const formattedTime = new Intl.DateTimeFormat('en-US', {
                hour: 'numeric',
                minute: '2-digit',
                hour12: true,
                timeZoneName: 'shortOffset',
            }).format(current);

            slots.push(formattedTime);
            current.setMinutes(current.getMinutes() + 30);  // Increment by 30 minutes
        }

        return slots;
    };

    // Generate time slots between midnight (0:00) and 24:00
    const timeSlots = generateTimeSlots(
        new Date().setHours(0, 0, 0, 0),
        new Date().setHours(23, 30, 0, 0)
    );


    const handleInputChange = (e) => {
        const { name, value } = e.target;
        if (name === "payNow") {
            setFormData((prevData) => ({
                ...prevData,
                payOffline: null,
                [name]: name,
            }));
        }
        else if (name === "payOffline") {
            setFormData((prevData) => ({
                ...prevData,
                payNow: null,
                [name]: name,
            }));
        }
        else {
            setFormData((prevData) => ({
                ...prevData,
                [name]: value,
            }));
        }

    };


    const handleGuestCountChange = (selectedOption) => {
        setFormData((prevData) => ({
            ...prevData,
            guestCount: selectedOption.value,
        }));
    };

    // Handle date change for the DatePicker
    const handleDateChange = (date) => {
        setFormData((prevData) => ({
            ...prevData,
            date: date,
        }));
    };

    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        })
    }

    const displayRazorpay = async () => {
        setLoaded(false);
        const res = await loadScript("https://checkout.razorpay.com/v1/checkout.js");
        setLoaded(true)
        if (!res) {
            console.log("Razorpay SDK failed to load. Are you online?");
            return;
        }
        // creating a new order
        setLoaded(false);
        let paymentAmount = GstAddedPrice;
        const result = await axiosApiInstance.post(BASE_URL + "/api/bookEvent/razor/check-out", { eventReqId: formId, currencyValue: selectedCurrency, totalAmount: Math.round(paymentAmount) });
        setLoaded(true)
        if (!result) {
            console.log("Server error. Are you online?");
            return;
        }
        const { order_id, transactionId } = result && result.data && result.data.data
        const currency = "INR";
        const options = {
            key: RAZOR_PAY_API_KEY,
            // amount: amount.toString(),
            currency: currency,
            name: formDate.orgName,
            description: "Event Booking",
            order_id: order_id,
            handler: async function (response) {
                const data = {
                    transactionId: transactionId,
                    payId: response.razorpay_payment_id,
                    formId: formId

                };
                setLoaded(false)
                const result = await axiosApiInstance.post(BASE_URL + "/api/bookEvent/event-payment/verify", data);
                setLoaded(true)
                if (result.status === 200) {
                    window.gtag("event", "Event Booking", {
                        event_category: "USER",
                        event_label: "Users Book ExtramilePlay Event "
                    });

                    // successAlert("Payment sucessful");
                    setOpenEventPaymentSuccess(true)
                    // setTimeout(() => {
                    //     history.push("/book-event")
                    // }, 1200)

                }
            },
            prefill: {
                name: formDate.clientFullName,
                email: formDate.email,
                contact: formDate.phNo,
            },
            notes: {
                address: "Gaurav Kumar Corporate Office",
            },
            theme: {
                color: "#e25569",
            },
        };
        const paymentObject = new window.Razorpay(options);
        paymentObject.open();
    }

    const handleContinue = async (e) => {
        // displayRazorpay1();
        e.preventDefault()
        if (errorMessage && errorMessage.membersCount) {
            return failureAlert(errorMessage.membersCount)
        }


        if (eventGameInfo && formDate && formDate.address && formDate.clientFullName && formDate.date && formDate.email && formDate.guestCount && formDate.orgName
            && formDate.phNo && formDate.startTime && (formDate.payNow === "payNow" || formDate.payOffline === "payOffline")
        ) {
            const body = {
                data: {
                    clientFullName: formDate.clientFullName.toLowerCase(),
                    startDate: formDate.date,
                    startTime: formDate.startTime,
                    guestCount: formDate.guestCount,
                    eventGameId: eventGameInfo.id,
                    companyName: formDate.orgName.toLowerCase(),
                    address: formDate.address.toLowerCase(),
                    mobileNo: formDate.phNo,
                    paymentType: formDate.payOffline ? "Offline" : formDate.payNow ? 'Online' : 'Online',
                    eventCost: GstAddedPrice
                    // postalCode:
                }
            }
            setLoaded(false)
            const updateForm = await axiosApiInstance.put(BASE_URL + "/api/bookEvent/edit-event/form/" + formId, body)
            if (updateForm && updateForm.data.message === 'event form updated sucessfully') {
                if (formDate.payNow === "payNow" && formDate.payOffline === null) {

                    displayRazorpay()
                }
                else if (formDate.payOffline === "payOffline" && formDate.payNow === null) {

                    setOpenEventPaymentSuccess(true)
                    setLoaded(true)
                  

                }
            }
        }
        else if (eventGameInfo && formDate && formDate.address && formDate.clientFullName && formDate.date && formDate.email && formDate.guestCount && formDate.orgName
            && formDate.phNo && formDate.startTime && (formDate.payNow !== "payNow" || formDate.payOffline !== "payOffline")) {
            failureAlert("Please select payment option")
        }
        else {
            setFormDataTouch({
                email: true,
                guestCount: true,
                phoneNo: true,
                clientFullName: true,
                orgName: true,
                address: true,
                startDate: true,
                startTime: true
            });
            failureAlert("Please fill all filed correctly")
        }
    }


    const TitleFormatter = ({ title }) => {
        const words = title.split(' ');

        return (
            <h1 className="text-[21px] font-sans font-semibold md:font-firaSans lg:font-firaSans mt-[15px]">
                {words.map((word, index) => {
                    // Check if there are more than two words
                    if (words.length > 2) {
                        return (
                            <span key={index}
                                style={{ color: index < 2 ? 'black' : '#E25569' }}
                                className="text-[21px] font-sans font-semibold"
                            >
                                {word}{' '}
                            </span>
                        );
                    }
                    // For exactly two words
                    else if (words.length === 2) {
                        return (
                            <span
                                key={index}
                                style={{ color: index === 0 ? 'black' : '#E25569' }}
                                className="text-[21px] font-sans font-semibold"

                            >
                                {word}{' '}
                            </span>
                        );
                    }
                    return null; // In case of unexpected input
                })}
            </h1>
        );
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);

        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return date.toLocaleDateString('en-US', options);
    };


    if (!eventGameInfo || eventGameInfo.minPlayers === undefined || eventGameInfo.maxPlayers === undefined) {
        return <p>Loading participants data...</p>;
    }

    // Generate options based on minPlayers and maxPlayers
    const options = Array.from({ length: eventGameInfo.maxPlayers - eventGameInfo.minPlayers + 1 }, (_, i) => ({
        value: eventGameInfo.minPlayers + i,
        label: `${eventGameInfo.minPlayers + i} participants`,
    }));

    // Add option for maxPlayers+
    options.push({ value: `${eventGameInfo.maxPlayers + 1}`, label: `${eventGameInfo.maxPlayers}+ participants` });

    // Custom styles for react-select
    const customStyles = {
        control: (provided) => ({
            ...provided,
            border: 'none',
            outline: 'none',
            boxShadow: 'none',
            '&:hover': {
                border: 'none',
                outline: 'none',
            },
        }),
        menu: (provided) => ({
            ...provided,
            zIndex: 9999,
            overflowY: 'auto',
            maxHeight: 400, // Set max height to trigger scrollbar
        }),
        option: (provided, state) => ({
            ...provided,
            // Optional: Add hover effect for options
            backgroundColor: state.isFocused ? '#f0f0f0' : 'white', // Change color on hover
            color: 'black', // Text color
        }),
    };


    return (
        <>
            <Header profile {...props} signOut={signOut} {...props} isAdmin={isAdmin} />

            <ToastContainer position="bottom-center" />
            <LoadingComponent loaded={loaded} />
            {
                openEventPaymentSuccess &&
                <EventPaymentSuccess modalid={'payment-success-modal'} toggle fromNormalPurchase={true} setOpenPaymentSuccessModal={setOpenEventPaymentSuccess} po={formDate.payNow === "payNow" && formDate.payOffline === null ? false : true} />
            }
            <div className="flex flex-col-reverse md:flex-row lg:flex-row w-full h-auto items-start pt-[111px] pr-[0px] pl-[0px]  md:pl-[30px] lg:pl-[100px] mb-[75px]">

                <div className="flex flex-col w-[100%] md:w-[45%] lg:w-[45%] pl-[25px] pr-[25px] md:pl-0 md:pr-0 lg:pl-0 lg:pr-0" >
                    {/* <h1 className=" hidden md:flex lg:flex text-[18px] sm:text-[21px] lg:text-[28px] md:text-[21px] " style={{ fontFamily: 'firaSans-medium' }}>1. Enter Your Event & <span>&nbsp;</span> <span className="text-[18px] sm:text-[21px] lg:text-[28px] md:text-[21px] text-[#E25569]" style={{ fontFamily: 'firaSans-medium' }}>Contact Details</span></h1> */}
                    <h1 className="hidden md:flex lg:flex text-[18px] sm:text-[21px] lg:text-[28px] md:text-[21px]" style={{ fontFamily: 'firaSans-medium', whiteSpace: 'nowrap' }}
                        ref={topDiv}
                    >
                        1. Enter Your Event and&nbsp;
                        <span className="text-[18px] sm:text-[21px] lg:text-[28px] md:text-[21px] text-[#E25569]" style={{ fontFamily: 'firaSans-medium' }}>
                            Contact&nbsp;
                        </span>
                        <span className="text-[18px] sm:text-[21px] lg:text-[28px] md:text-[21px] text-[#E25569]" style={{ fontFamily: 'firaSans-medium' }}>
                            Details
                        </span>
                    </h1>



                    <form className="flex flex-col mt-[45px] md:mt-[35px] lg:mt-[45px] w-full">
                        <div className="flex flex-col lg:flex-row w-full">
                            <div className="flex flex-col w-100% lg:w-[45%]">
                                <label className="text-[18px] sm:text-[16px] md:text-[20px] lg:text-[21px] mb-[8px] font-firaSans" >Date</label>
                                <div className="flex h-auto lg:h-[67px] flex-col items-start w-full rounded-[6px] mb-[10px]" >

                                    <div className={`flex items-center w-full rounded-[6px] h-[50px] border-[0.5px] pl-[10px] pr-[10px]  cursor-pointer  ${errorMessage.date ? "phone-input-border " : "border-[#707070]"}`} onClick={handleFilterTab}>
                                        <div className="flex items-center max-w-full w-full h-full"
                                        // onClick={handleInputChange}
                                        >
                                            <DatePicker
                                                selected={formDate.date}
                                                // onChange={(date) => setStartDate(date)}
                                                onChange={handleDateChange}
                                                dateFormat="yyyy/MM/dd"
                                                filterDate={isDisabledDate}
                                                ref={datePickerRef}
                                                minDate={new Date()}
                                                placeholderText="Select Date"
                                                className="w-full h-full focus:outline-none"
                                            // onBlur={() => setFormDataTouch(prevState => ({ ...prevState, startDate: true }))}

                                            />
                                        </div>

                                        <div
                                            className="flex w-[20px] h-full items-center justify-center "
                                        >
                                            <img src={calenderIcon} alt="calender" />
                                        </div>
                                    </div>
                                    {errorMessage.date &&
                                        <div className="error-message" style={{ position: 'static' }}>{errorMessage.date && errorMessage?.date ? errorMessage?.date : ''}</div>
                                    }
                                </div>
                            </div>
                            <div className="flex flex-col w-[100%] lg:w-[55%] lg:ml-[30px] mt-[25px] lg:mt-0">
                                <label className="text-[18px] sm:text-[16px] md:text-[20px] lg:text-[21px] mb-[8px] font-firaSans" >Select Start Time</label>
                                <div className="flex h-auto lg:h-[67px] flex-col items-start w-full rounded-[6px]  mb-[10px]">

                                    <div className={`flex items-center w-full rounded-[6px] h-[50px] border-[0.5px]  pr-1  ${errorMessage.startTime ? "phone-input-border " : "border-[#707070]"}`}>
                                        <select
                                            id="timeSelect"
                                            value={formDate.startTime}
                                            name="startTime"
                                            // onChange={(e) => setSelectedTime(e.target.value)}
                                            onChange={handleInputChange}
                                            className="w-full text-center py-2 px-4 border-none z-0"
                                            onBlur={() => setFormDataTouch(prevState => ({ ...prevState, startTime: true }))}


                                        >
                                            <option value="" className="text-center ">-- Select Time --</option>
                                            {timeSlots.map((time, index) => (
                                                <option key={index} value={time} className="text-center ">
                                                    {time}
                                                </option>
                                            ))}
                                        </select>

                                    </div>
                                    {errorMessage.startTime &&
                                        <div className="error-message" style={{ position: 'static' }}>{errorMessage.startTime && errorMessage?.startTime ? errorMessage?.startTime : ''}</div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-col w-[100%] mt-[25px]">
                            <label className="text-[18px] sm:text-[16px] md:text-[20px] lg:text-[21px] mb-[8px] font-firaSans" >
                                Approximately how many participants?
                                
                            </label>
                            <div className={`flex items-center w-full rounded-[6px] h-[50px] border-[0.5px] pr-1 mb-[10px]  ${errorMessage.membersCount ? "phone-input-border " : "border-[#707070]"}`}>
                               
                                <select
                                    name="guestCount"
                                    id="participants"
                                    className="block w-full h-full outline-none border-none focus:outline-none focus:border-none text-[16px] font-sans pl-3 z-0"
                                    style={{ maxHeight: '150px', overflowY: 'scroll' }}
                                    value={formDate.guestCount}
                                    onChange={handleInputChange}
                                    onBlur={() => setFormDataTouch(prevState => ({ ...prevState, membersCount: true }))}
                                >
                                    {eventGameInfo && eventGameInfo.minPlayers && eventGameInfo.maxPlayers ? (
                                        <>

                                            {Array.from({ length: eventGameInfo.maxPlayers - eventGameInfo.minPlayers + 1 }, (_, i) => (
                                                <option key={i} value={eventGameInfo.minPlayers + i}>
                                                    {eventGameInfo.minPlayers + i} participants
                                                </option>
                                            ))}


                                            {/* <option value={`${eventGameInfo.maxPlayers}+`}>
                                                {eventGameInfo.maxPlayers}+ participants
                                            </option> */}
                                        </>
                                    ) : (
                                        <option value="">No participants available</option>
                                    )}
                                </select>
                          

                            </div>
                            <div className=" flex h-[25px] w-full">
                                {
                                    errorMessage && errorMessage?.membersCount ? (
                                        <span className="text-red-500 text-[10px] font-sans">{errorMessage.membersCount}</span>
                                    )
                                        : null
                                }
                            </div>

                        </div>

                        <div className="flex flex-col w-full items-start mt-[35px]">
                            <label className="text-[18px] sm:text-[16px] md:text-[20px] lg:text-[21px] mb-[8px] font-firaSans" >Email*</label>
                            <div className="flex items-center w-full rounded-[6px] h-[50px] border-[#707070] border-[0.5px] pl-[20px] p-[10px] mb-[10px]">
                                <input type="text" className="w-full h-full focus:outline-none focus:border-none text-[16px] font-sans !bg-white" placeholder="Enter Email ID"
                                    onChange={handleInputChange}
                                    name="email"
                                    value={formDate.email}
                                    disabled
                                />
                            </div>

                            <label className="text-[18px] sm:text-[16px] md:text-[20px] lg:text-[21px] mb-[8px] font-firaSans mt-[10px]" >Enter Full Name*</label>
                            <div className="flex flex-col items-start w-full rounded-[6px] mb-[10px]">

                                <div className={`flex items-center w-full rounded-[6px] h-[50px] border-[0.5px] pl-[20px] p-[10px]  ${errorMessage.clientFullName ? "phone-input-border " : "border-[#707070]"}`}>
                                    <input type="text" className="w-full h-full focus:outline-none focus:border-none text-[16px] font-sans !bg-white" placeholder="Enter Name"
                                        onChange={handleInputChange}
                                        name="clientFullName"
                                        value={formDate.clientFullName}
                                        onBlur={() => setFormDataTouch(prevState => ({ ...prevState, clientFullName: true }))}
                                        disabled={userInfo && userInfo?.data && userInfo?.data?.firstName ? true : false}
                                    />
                                </div>
                                {errorMessage.clientFullName &&
                                    <div className="error-message" style={{ position: 'static' }}>{errorMessage.clientFullName && errorMessage?.clientFullName ? errorMessage?.clientFullName : ''}</div>
                                }
                            </div>

                            <div className="flex flex-col lg:flex-row w-full mt-[10px] items-center">

                                <div className="flex flex-col w-full lg:w-[50%] items-start">
                                    <label className="text-[18px] sm:text-[16px] md:text-[20px] lg:text-[21px] mb-[8px] font-firaSans" >Company Name*</label>
                                    <div className="flex h-auto lg:h-[67px] flex-col items-startr w-full rounded-[6px] mb-[10px]">

                                        <div className={`${errorMessage.orgName ? "phone-input-border " : "border-[#707070]"} flex items-center w-full rounded-[6px] h-[50px] border-[#707070] border-[0.5px] pl-[20px] p-[10px]`}>
                                            <input type="text" className="w-full h-full focus:outline-none focus:border-none text-[16px] font-sans !bg-white" placeholder="Enter Company Name"
                                                onChange={handleInputChange}
                                                name="orgName"
                                                value={formDate.orgName}
                                                onBlur={() => setFormDataTouch(prevState => ({ ...prevState, orgName: true }))}
                                                disabled={orgDetailsByEmail && orgDetailsByEmail?.data && orgDetailsByEmail?.data?.name ? true : false}

                                            />
                                        </div>
                                        {errorMessage.orgName &&
                                            <div className="error-message" style={{ position: 'static' }}>{errorMessage.orgName && errorMessage?.orgName ? errorMessage?.orgName : ''}</div>
                                        }
                                    </div>
                                </div>

                                <div className="flex flex-col w-full lg:w-[50%] items-start lg:pl-[21px]">
                                    <label className="text-[18px] sm:text-[16px] md:text-[20px] lg:text-[21px] mb-[8px] font-firaSans" >Enter Phone Number*</label>
                                    <div className={`flex  h-auto lg:h-[67px] flex-col items-startr w-full rounded-[6px]  mb-[10px]`}>

                                        <div className={`flex items-center w-full rounded-[6px] h-[50px]  border-[0.5px]  ${errorMessage.phoneNo ? "phone-input-border " : "border-[#707070]"}`}>
                                        

                                            <PhoneInput
                                                type="numbers"
                                                className={"w-full h-full rounded-[7px] !bg-white"}
                                                name="phoneNo"
                                                enableLongNumbers
                                                // placeholder="+91 98765-43210"
                                                country="in"
                                                onChange={(val, country, e, formattedValue) => {
                                                    if (country && ((country.format && formattedValue && formattedValue.length === country.format.length) || (val.length <= country.dialCode.length))) {
                                                        setValidPhone(true);
                                                        setFormDataTouch(prevState => ({ ...prevState, phoneNo: true }));
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            phNo: val
                                                        }))
                                                    }
                                                    else {
                                                        setValidPhone(false);
                                                        setFormDataTouch(prevState => ({ ...prevState, phoneNo: true }));
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            phNo: val
                                                        }))
                                                    }
                                                }}
                                                value={formDate.phNo ? formDate.phNo : defaultinputValue.phoneNo}
                                                onFocus={() => {
                                                    setDefaultInputeValue((prev) => ({ ...prev, phoneNo: "" }))
                                                    if (!formDate.phNo) {
                                                        setFormData((prevState) => ({
                                                            ...prevState,
                                                            phNo: "+91"
                                                        }))
                                                        setValidPhone(true);
                                                    }
                                                }}
                                                id="phoneNo"
                                                containerStyle={{
                                                    borderBottom: "1px solid #bfc1d7",
                                                }}
                                            />

                                        </div>
                                        {errorMessage.phoneNo &&
                                            <div className="error-message" style={{ position: 'static' }}>{errorMessage.phoneNo && errorMessage?.phoneNo ? errorMessage?.phoneNo : ''}</div>
                                        }
                                    </div>

                                </div>
                            </div>

                            <label className="text-[18px] sm:text-[16px] md:text-[20px] lg:text-[21px] mb-[8px] font-firaSans mt-[10px]" >Address*</label>
                            <div className="flex flex-col items-start w-full rounded-[6px]  mb-[10px]">

                                <div className={`  ${errorMessage.address ? "phone-input-border " : "border-[#707070]"} flex items-center w-full rounded-[6px] h-[50px] border-[0.5px] pl-[20px] p-[10px] `}>
                                    <input type="text" className="w-full h-full focus:outline-none focus:border-none text-[16px] font-sans" placeholder="Enter Name"
                                        onChange={handleInputChange}
                                        name="address"
                                        value={formDate.address}
                                        onBlur={() => setFormDataTouch(prevState => ({ ...prevState, address: true }))}

                                    />
                                </div>
                                {errorMessage.address &&
                                    <div className="error-message" style={{ position: 'static' }}>{errorMessage.address && errorMessage?.address ? errorMessage?.address : ''}</div>
                                }
                            </div>


                        </div>
                    </form>


                    <div className="flex flex-row w-full items-center mt-[30px] ">
                        <label className="flex items-center cursor-pointer">
                            <input
                                type="radio"
                                className=" accent-themeColor cursor-pointer"
                                checked={formDate.payNow === 'payNow'}
                                name="payNow"
                                onChange={handleInputChange}
                            />
                            <span className="text-[18px] font-firaSans ml-[10px]">Pay Now</span>

                        </label>
                        <span className="text-[14px] font-firaSans ml-[20px]">(Play now and get 20% off)</span>
                    </div>
                    <div className="flex w-full items-center mt-[30px]">
                        <label className="flex items-center cursor-pointer">
                            <input
                                type="radio"
                                className=" accent-themeColor cursor-pointer"
                                name="payOffline"
                                checked={formDate.payOffline === 'payOffline'}
                                onChange={handleInputChange}
                            />
                            <span className="text-[18px] font-firaSans ml-[10px] ">Pay Offline</span>

                        </label>
                    </div>
                    <div className="flex items-center mt-[60px]">
                        <button className="btn btn-primary w-[150px]"
                            onClick={handleContinue}
                        >Continue</button>
                    </div>
                </div>

                <div className="flex flex-col md:flex-row lg:flex-row justify-center w-[100%] md:w-[55%] lg:w-[55%] items-center md:items-end lg:items-end pl-0 pr-0 md:pl-[30px] md:pr-[25px] lg:pr-0 lg:pl-[60px]">
                    <h1 className=" flex md:hidden lg:hidden text-[21px] sm:text-[28px] lg:text-[28px] md:text-[24px] mb-[35px] font-sans font-semibold pr-2 whitespace-nowrap">{`1. Enter Your Event & `}<span>&nbsp;</span><span className="text-[21px] sm:text-[28px] lg:text-[28px] md:text-[24px] text-[#E25569] whitespace-nowrap"> {` Contact Details`}</span></h1>

                    <div className="flex flex-col p-[20px] rounded-[6px] w-full md:w-[550px] lg:w-[550px] h-auto" style={{ boxShadow: '0px 4px 34px #00000029' }}>
                        <div className="flex w-full h-[209px]">
                            <img src={eventGameInfo ? `${S3_BASE_URL}${eventGameInfo?.gameBanner}` : ''} alt="game-logo" className="!w-full" />
                        </div>
                        <TitleFormatter title={eventGameInfo ? eventGameInfo.title : ''} />
                        {/* <h2 className="text-[21px] font-sans font-semibold md:font-firaSans lg:font-firaSans mt-[15px]">The Ambitious <span className="font-firaSans text-[20px] text-[#E25569]">Voyage</span></h2> */}
                        <div className="flex w-full items-start flex-col mt-[10px]">
                            <span className="text-[18px] font-sans font-semibold ">Event Details</span>
                        

                            {
                                formDate && formDate?.date ? (
                                    <div className="flex w-full items-center flex-row mt-3">
                                        <div className="flex w-[25px] h-[25px] justify-center items-center">
                                          
                                            <SlCalender className=" w-[17px] h-[20px] text-black" />
                                        </div>
                                        <span className="text-[16px] ml-3">{`${formatDate(formDate.date)} at ${formDate && formDate?.startTime ? formDate.startTime : ''}`}</span>
                                    </div>
                                )
                                    : null
                            }
                            <div className="flex w-full items-center flex-row mt-2">
                                <div className="flex w-[25px] h-[25px] justify-center items-center">
                                    {/* <img src={attendeesIcon} alt="calendarIcon" /> */}
                                    <FaUserFriends className=" w-[18px] h-[20px] text-black" />
                                </div>
                                <span className="text-[16px] ml-3">{`${formDate ? formDate?.guestCount : 0} attendees`}</span>
                            </div>
                            <div className="flex w-full items-center flex-row mt-2">
                                <div className="flex w-[25px] h-[25px] justify-center items-center">
                                    {eventGameInfo && eventGameInfo?.typeOfEvent === "online" ? (
                                        <RiLiveLine className=" w-[21px] h-[21px] text-black" />

                                    ) : (
                                        <RiLiveLine className=" w-[21px] h-[21px] text-black" />

                                    )
                                    }
                                </div>
                                
                                <span className="text-[16px] ml-3">{eventGameInfo && eventGameInfo?.typeOfEvent === "online" ? 'Virtual' : eventGameInfo && eventGameInfo?.typeOfEvent === "offline" ? 'Onsite' : eventGameInfo?.typeOfEvent}</span>
                            </div>

                        </div>
                        {/* <span className="text-[16px] font-firaSans mt-[15px]">Price</span> */}
                        <div className="flex items-start flex-row justify-between mt-[15px]">
                            <span className="text-[16px] font-firaSans ">Price</span>
                            {/* <span className="text-[16px]">{formDate ? formDate?.guestCount : 0} People X {eventGameInfo ? Number(eventGameInfo?.costOfEvent[selectedCurrency]) : 0} pp</span> */}
                            <span className="text-[16px]">{`${selectedCurrency && selectedCurrency === "USD" ? '$' : '₹'} ${eventTotalCost}`}</span>

                        </div>

                        <div className="flex items-start flex-row justify-between mt-1">
                            <span className="text-[16px]">Services Fee</span>
                            <span className="text-[16px]">{`${selectedCurrency && selectedCurrency === "USD" ? '$' : '₹'} ${0.00}`}</span>

                        </div>
                        {
                            eventTotalCost && disCountedAmount > 0 && formDate && formDate.payNow ? (
                                <div className="flex items-start flex-row justify-between mt-1">
                                    <span className="text-[16px] text-lime-500"> 20% discount applied</span>
                                    <span className="text-[16px]">{`${selectedCurrency && selectedCurrency === "USD" ? '$' : '₹'} -${discount}`}</span>

                                </div>
                            )
                                : null
                        }
                        <div className="flex items-start flex-row justify-between mt-1">
                            <span className="text-[16px] font-firaSans">18% GST</span>
                            <span className="text-[16px] font-firaSans">{gstPerAmount ? `${selectedCurrency && selectedCurrency === "USD" ? '$' : '₹'} ${gstPerAmount}` : 0}</span>

                        </div>
                        <div className="flex items-start flex-row justify-between mt-1">
                            <span className="text-[20px] font-firaSans">Total ({selectedCurrency})</span>
                            <span className="text-[20px] font-firaSans">{GstAddedPrice ? `${selectedCurrency && selectedCurrency === "USD" ? '$' : '₹'}${GstAddedPrice}` : 0}</span>

                        </div>
                        <div className="flex w-full bg-[#FDF1F3] mt-[25px] rounded-[6px]">
                            <Accordion items={contents} isBottomBorder={true} />
                        </div>

                    </div>
                    {/* <div className=" w-[140px] h-[90px] fixed lg:relative bottom-[0px] right-[-60px] md:bottom-[30px] md:right-[-10px] lg:right-0 lg:bottom-0 lg:flex justify-center items-start z-50 ">
                        <div className="flex w-[65px] h-[75px] md:w-[95px] md:h-[85px] lg:w-[95px] lg:h-[85px] items-center justify-center ball">
                            <img src={play_happy} alt="happly-play-icon" />
                        </div>
                    </div> */}


                </div>

            </div>


            <Footer />

        </>
    )
}

export default BookAnEventForm1