
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import './modal.css'; // If you still need custom styles
import Modal from './modal';
import { ToastContainer } from 'react-toastify';
import iconfilter from "../../assets/icons/iconfilter.png";
import LoadingComponent from "../../components/loader/LoadingComponent";

import { updateFilterFormData } from "../../redux/actions/bookEventFilterActions";
import { BASE_URL, successAlert, encryptData, S3_BASE_URL, GOOGLE_RECAPTCHA_SITE_KEY, failureAlert, IsAdmin, axiosApiInstance, videoUrl } from "../../helpers/helper";
const BookEventFilterModal = ({ modalid, toggle, setOpenBookEventFilterModal, bookEventCategories, bookEventplatforms, onCategoriesChange, onClearFilters }) => {
    const dispatch = useDispatch();
    const storedFormData = useSelector((state) => state.filterFormData);
    const [loaded, setLoaded] = useState(true);
    const [checkedItems, setCheckedItems] = useState({});
    const [platformItems, setplatformcheckedItems] = useState({});
    const [participants, setParticipants] = useState('');
    const [minPrice, setMinPrice] = useState('');
    const [maxPrice, setMaxPrice] = useState('');
    const [errorParticipants, setErrorParticipants] = useState('');
    const [errorMinPrice, setErrorMinPrice] = useState('');
    const [errorMaxPrice, setErrorMaxPrice] = useState('');
    const minutes = [10, 15, 30, 45, 60, 75, 90, 120, 180];
    const [minutesChecked, setMinutesChecked] = useState({});
    const handleCheckboxChange = (id) => {
        setCheckedItems((prev) => ({
            ...prev,
            [id]: !prev[id],
        }));
    };

    const convertPlatformsArrayToObject = (platformsArray) => {
        return platformsArray.reduce((acc, platformId) => {
            acc[platformId] = true; 
            return acc;
        }, {});
    };
    const [selectedOption, setSelectedOption] = useState('');
    const [error, setError] = useState('');

   
    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);

    };
    useEffect(() => {
        if (storedFormData) {


            const data = storedFormData.formData;
            if (data) {
             
                setParticipants(data?.guestNumber ? data.guestNumber : '');
                setMinPrice(data?.minCost ? data.minCost : '');
                setMaxPrice(data?.maxCost ? data.maxCost : '');
                const categoryIds = convertPlatformsArrayToObject(data?.categoryIds);
                const minutes = convertPlatformsArrayToObject(data?.minutes);
                const platformsObject = convertPlatformsArrayToObject(data?.platforms);
                setMinutesChecked(minutes)
                setCheckedItems(categoryIds);
                setplatformcheckedItems(platformsObject);
                setSelectedOption(data?.typeOfEvent ? data.typeOfEvent: '')
            }


        }
    }, [storedFormData]);

    const handleMinutesChange = (minute) => {
        setMinutesChecked((prev) => ({
            ...prev,
            [minute]: !prev[minute],
        }));
    };
    const handleplatformChange = (id) => {
        setplatformcheckedItems((prev) => ({
            ...prev,
            [id]: !prev[id],
        }));
    };
    const handleTextInputChange = (e, setInput) => {
        const value = e.target.value;
        // Allow only numbers
        if (/^\d*$/.test(value) || value === '') {
            setInput(value);
           
            if (setInput === setParticipants) setErrorParticipants('');
            if (setInput === setMinPrice) setErrorMinPrice('');
            if (setInput === setMaxPrice) setErrorMaxPrice('');
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        let isValid = true;

        // Validate participants
        if (!/^\d+$/.test(participants)) {
            setErrorParticipants('Please fill out this field');
            isValid = false;
        }


        if (parseInt(maxPrice) <= parseInt(minPrice) && maxPrice !== '') {
            setErrorMaxPrice('Max Price should be greater than Min Price');
            isValid = false;
        }

        if (isValid) {

            const selectedIds = Object.keys(checkedItems).filter(id => checkedItems[id]);
            const selectedPlatforms = Object.keys(platformItems).filter(id => platformItems[id]);
            const selectedMinutes = Object.keys(minutesChecked).filter(min => minutesChecked[min]);

            setLoaded(false);
            let obj = { categoryIds: selectedIds, minCost: minPrice, maxCost: maxPrice, platforms: selectedPlatforms, minutes: selectedMinutes.map(Number), guestNumber: Number(participants), typeOfEvent: selectedOption };

            const result = await axiosApiInstance.post(BASE_URL + "/api/bookEvent/filterGames", obj)
                .then((response) => {

                    onCategoriesChange(response.data.data);
                    setOpenBookEventFilterModal(false);
                    setParticipants('');
                    setMinPrice('');
                    setMaxPrice('');
                    setLoaded(true);
                    dispatch(updateFilterFormData(obj));

                })
                .catch((error) => {
                    failureAlert("Failed to get games");
                    setLoaded(true);
                });
        }
    };
    const handleClearFilters = () => {
   
        setParticipants('');
        setMinPrice('');
        setMaxPrice('');
        setMinutesChecked({});
        setplatformcheckedItems({});
        setCheckedItems({});
        onClearFilters(true);
        dispatch(updateFilterFormData(null));
    };

    return (
        <>

            <ToastContainer position='bottom-center' />
            <LoadingComponent loaded={loaded} />
            <Modal modalid={modalid} toggle={toggle}>
                <div className="modal-body w-full max-w-[700px] bg-white overflow-hidden">
                    <ToastContainer position='bottom-center' />
                    <form className="mb-2 md:mb-4 flex flex-col h-[89vh]" onSubmit={handleSubmit}>
                        <div className="w-full flex-grow">
                            <div className="flex justify-between items-center mb-0" style={{ boxShadow: '0px 3px 6px #00000029' }}>
                                <div className="flex items-center justify-start flex-grow ml-8 md:justify-center md:ml-0">
                                    <img
                                        src={iconfilter} 
                                        alt="Filter Icon"
                                        className="mr-2" 
                                    />
                                    <h2 className="text-xl font-bold">Filters</h2>
                                </div>
                                <button
                                    style={{ fontSize: '40px' }}
                                    className="text-black-500 hover:text-gray-800 mr-6"
                                    onClick={() => setOpenBookEventFilterModal(false)}
                                >
                                    &times; 
                                </button>
                            </div>

                            <div className='max-h-[70vh] overflow-y-auto'>
                                <div className="p-6 pb-0">
                                    <h4 className="text-md font-bold mb-4">Group Size & Budget</h4>

                                    <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 mb-4">
                                        <div className="w-full md:w-1/2">
                                            <label htmlFor="participants" className="text-sm font-bold mb-4">Approximately How Many Guests</label>
                                            <input
                                                type="text"
                                                placeholder="Enter Participants"
                                                value={participants}
                                                onChange={(e) => handleTextInputChange(e, setParticipants)}
                                                className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full  p-2.5"
                                            />
                                            {errorParticipants && <div style={{ color: 'red', fontSize: '14px' }}>{errorParticipants}</div>}
                                        </div>
                                        <div className="w-full md:w-1/2">
                                            <label htmlFor="third_name" className="text-sm font-bold mb-4">Type of Event</label>

                                            <select
                                                className="bg-gray-50 border text-gray-900 text-sm rounded-lg  block w-full  bg-white"
                                                style={{ height: '40px' }}
                                                value={selectedOption}
                                                onChange={handleSelectChange}
                                            >
                                                <option value="" selected>
                                                    Select 
                                                </option>
                                                <option value="online">Virtual</option>
                                                <option value="offline">Onsite</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="flex flex-col md:flex-row space-y-2 md:space-y-0 md:space-x-4 mb-4">
                                        <div className="w-full md:w-1/2">
                                            <label htmlFor="second_name" className="text-sm font-bold mb-4">Min Price</label>
                                            <input
                                                type="text"
                                                placeholder="Eg: ₹100"
                                                value={minPrice}
                                                onChange={(e) => handleTextInputChange(e, setMinPrice)}
                                                className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                            />
                                        </div>
                                        <div className="w-full md:w-1/2">
                                            <label htmlFor="third_name" className="text-sm font-bold mb-4">Max Price</label>
                                            <input
                                                type="text"
                                                placeholder=" Eg: ₹1000"
                                                value={maxPrice}
                                                onChange={(e) => handleTextInputChange(e, setMaxPrice)}
                                                className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
                                            />
                                            {errorMaxPrice && <div style={{ color: 'red', fontSize: '14px' }}>{errorMaxPrice}</div>}
                                        </div>
                                    </div>

                                    <hr className="h-px my-4 bg-gray-200 border-0 " />
                                    <div className="mb-2 md:mb-4">
                                        <h6 className="text-sm font-bold mb-4">Categories</h6>
                                        <div className="pb-0">
                                            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                                                {bookEventCategories.map(option => (
                                                    <div className="flex items-center" key={option.id}>
                                                        <input
                                                            id={`category-${option.id}`}
                                                            type="checkbox"
                                                            checked={!!checkedItems[option.id]}
                                                            onChange={() => handleCheckboxChange(option.id)}
                                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                                                        />
                                                        <label htmlFor={`category-${option.id}`} className="ml-2 ms-2 text-sm font-medium text-gray-900">{option.title}</label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>

                                    <hr className="h-px my-4 bg-gray-200 border-0 " />

                                    <div className="mb-2 md:mb-4">
                                        <h6 className="text-sm font-bold mb-4">How Long Is Your Event?</h6>
                                        <div className="border-b pb-4">
                                            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                                                {minutes.map(min => (
                                                    <div className="flex items-center" key={min}>
                                                        <input
                                                            id={`minute-${min}`}
                                                            type="checkbox"
                                                            checked={!!minutesChecked[min]}
                                                            onChange={() => handleMinutesChange(min)}
                                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                                                        />
                                                        <label htmlFor={`minute-${min}`} className="ml-2 ms-2 text-sm font-medium text-gray-900">{min} minutes</label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>

                                    <div className="mb-0 md:mb-0">
                                        <h6 className="text-sm font-bold mb-4">Supported Video Platforms</h6>
                                        <div className="pb-0">
                                            <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                                                {bookEventplatforms.map(platform => (
                                                    <div className="flex items-center" key={platform.id}>
                                                        <input
                                                            id={`platform-${platform.id}`}
                                                            type="checkbox"
                                                            checked={!!platformItems[platform.id]}
                                                            onChange={() => handleplatformChange(platform.id)}
                                                            className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500"
                                                        />
                                                        <label htmlFor={`platform-${platform.id}`} className="ml-2 ms-2 text-sm font-medium text-gray-900">{platform.name}</label>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-center m-4 w-[95%]">
                            <button
                                onClick={handleClearFilters}
                                className="mr-3 w-[40%] py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-md border border-gray-900 font-bold"
                            >
                                Clear All
                            </button>
                            <button
                                type="submit"
                                className="w-[60%] py-2.5 px-5 me-2 mb-2 text-sm font-medium text-gray-900 focus:outline-none bg-white rounded-lg border border-gray-200  btn btn-primary"
                            >
                                Filter Results
                            </button>

                        </div>




                    </form>
                </div>

            </Modal>
        </>
    );
};

export default BookEventFilterModal;